<template>
  <div>
    <UdefLoader v-if="loading" />
    <section v-else>
      <UdefHero blur :img="getEventImg(event.image, 1000)">
        <div class="hero-event">
          <v-img class="img" :src="getEventImg(event.image, 1000)" />
          <div class="wrapper">
            <h1>{{ event.title }}</h1>
            <p class="information-row">
              <UdefIcon class="icon" color="white" icon="calendar" />
              {{ getDateRangeFormatted(new Date(event.dateStart), new Date(event.dateEnd)) }}
            </p>

            <p class="information-row">
              <UdefIcon class="icon" color="white" icon="time" />
              {{ getTimeRangeFormatted(new Date(event.dateStart), new Date(event.dateEnd)) }}
            </p>

            <p v-if="event.locationAddress" class="information-row">
              <UdefIcon class="icon" color="white" icon="location" />
              {{ event.locationAddress }}
            </p>

            <p v-if="event.website" class="information-row">
              <UdefIcon class="icon" color="white" icon="link" />
              {{ event.website }}
            </p>

            <p class="information-row">
              <UdefIcon class="icon" color="white" icon="dollar" />
              {{ totalTierCost }}
            </p>

            <UdefButton
              :href="`${breakkonnectUrl}/event/${event.id}`"
              :width="$vuetify.breakpoint.mdAndDown ? '100%' : '305'"
              height="52"
              color="#00BF70"
              class="purchase-btn"
              dark
            >
              <span v-if="event.period === 'past'">View event</span>
              <span v-else>Purchase ticket on Break Konnect</span>
            </UdefButton>

            <span
              v-if="event.period !== 'past'"
              class="redirect-info"
            >You will be re-directed breakkonnect.com to finalzie the
              purchase</span>
          </div>
        </div>
      </UdefHero>
      <section class="content udef-content">
        <div class="share-mobile">
          <h3 class="content-title">
            Share the event
          </h3>
          <div class="socials">
            <a target="_blank" :href="facebookShareUrl">
              <img src="/img/icons/facebook.svg" alt="facebook">
            </a>
            <a target="_blank" :href="twitterShareUrl">
              <img src="/img/icons/twitter.svg" alt="twitter">
            </a>
          </div>
        </div>
        <div class="battles">
          <h3 class="content-title">
            Battles
          </h3>
          <UdefBattleCard
            v-for="(battle, index) in event.battles"
            :key="index"
            class="battle"
            :battle="battle"
            @click="showBattle(battle)"
          />
        </div>
        <div class="information">
          <div class="share">
            <h3 class="content-title">
              Share the event
            </h3>
            <div class="socials">
              <a target="_blank" :href="facebookShareUrl">
                <img src="/img/icons/facebook.svg" alt="facebook">
              </a>
              <a target="_blank" :href="twitterShareUrl">
                <img src="/img/icons/twitter.svg" alt="twitter">
              </a>
            </div>
          </div>
          <div v-if="event.description" class="description">
            <h3 class="content-title">
              Description
            </h3>
            <div
              v-html="$sanitize(event.description)"
              v-if="event.description"
              class="text description-text" />

          </div>
          <div class="staff-row">
            <h3 class="content-title">
              Organizer
            </h3>
            <UdefUserChip :user="event.staff.organizer" />
          </div>
          <div v-if="event.staff.hosts.length" class="staff-row">
            <h3 class="content-title">
              Hosts
            </h3>
            <UdefUserChip
              v-for="host in event.staff.hosts"
              :key="host.id"
              class="mb-2"
              :user="host"
            />
          </div>
          <div v-if="event.staff.djs.length" class="staff-row">
            <h3 class="content-title">
              Djs
            </h3>
            <UdefUserChip
              v-for="dj in event.staff.djs"
              :key="dj.id"
              class="mb-2"
              :user="dj"
            />
          </div>
          <div v-if="event.whoIsGoing.length" class="staff-row">
            <h3 class="content-title">
              Who’s going
            </h3>
            <div class="d-flex">
              <UdefUserChip
                v-for="(participant, index) in event.whoIsGoing.slice(0, 4)"
                :key="index"
                hide-name
                :user="participant"
              />
              <v-avatar
                v-if="event.whoIsGoing.length > 4"
                class="white--text"
                color="#666666"
                :size="48"
              >
                +{{ event.whoIsGoing.length - 4 }}
              </v-avatar>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import api from '@/api'

import Images from '@/mixins/image'
import Dates from '@/mixins/date'

import UdefBattleCard from '@/components/UdefBattleCard'
import UdefUserChip from '@/components/UdefUserChip'
import { getTimeRangeFormatted, getDateRangeFormatted } from '@/services/date'
export default {
  name: 'Event',
  components: {
    UdefBattleCard,
    UdefUserChip
  },
  mixins: [Images, Dates],
  data() {
    return {
      breakkonnectUrl: process.env.VUE_APP_BREAKKONECT_URL,
      loading: true,
      event: {}
    }
  },
  computed: {
    totalTierCost() {
      if (!this.event.tiers.length) {
        return 'Free'
      }
      const costs = this.event.tiers.map(tier => tier.cost / 100)
      const min = Math.min(...costs)
      const max = Math.max(...costs)
      if (min === Infinity || max === Infinity) {
        return ' '
      }
      if (max === 0) {
        return 'Free'
      }
      if (min === max) {
        return `${max}`
      }
      return `$${min} - ${max}`
    },
    eventUrl() {
      return window.location.href
    },
    twitterShareUrl() {
      const twitterUrl = 'https://twitter.com/intent/tweet'
      const shareUrl = `${twitterUrl}?text=${
        this.event.title
      } - ${this.getDateRangeFormatted(new Date(this.event.dateStart), new Date(this.event.dateEnd))} -
      ${this.getDateRangeFormatted(new Date(this.event.dateStart), new Date(this.event.dateEnd))} - ${
        this.event.locationAddress
      }&url=${this.eventUrl}`
      return shareUrl
    },
    facebookShareUrl() {
      const facebookUrl = 'https://www.facebook.com/sharer/sharer.php'
      const shareUrl = `${facebookUrl}?u=${encodeURI(this.eventUrl)}`
      return shareUrl
    }
  },
  async mounted() {
    this.event = await api.events.getEvent(this.$route.params.id)
    this.loading = false
  },
  methods: {
    getTimeRangeFormatted,
    getDateRangeFormatted,
    showBattle(battle) {
      window.open(`${this.breakkonnectUrl}/battle/${battle.id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/global.scss";

.hero-event {
  padding: 60px 165px;
  display: flex;
  align-items: center;

  @media screen and (max-width: $screen-md) {
    flex-direction: column;
    padding: 40px 20px;
  }

  .img {
    width: 535px;
    height: 535px;
    margin-right: 40px;

    @media screen and (max-width: $screen-md) {
      width: 335px;
      height: 335px;
      margin: auto;
    }
  }

  .wrapper {
    z-index: 1;
    max-width: 535px;
    width: 100%;
    color: $white;
  }

  h1 {
    line-height: 64px;
    font-size: $font-section-title;

    @media screen and (max-width: $screen-md) {
      margin-top: 20px;
      line-height: 40px;
      font-size: $font-section-title-mobile;
    }
  }

  .information-row {
    margin-top: 20px;
    margin-bottom: 0;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 8px;
    }
  }

  .purchase-btn {
    margin-top: 40px;
  }

  .redirect-info {
    display: block;
    max-width: 242px;
    font-size: 12px;
    padding-top: 12px;
  }
}

.content {
  padding: 40px 165px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: $screen-md) {
    flex-direction: column;
    padding: 40px 20px;
  }

  .content-title {
    margin-bottom: 6px;
  }

  .battles {
    max-width: 650px;
    width: 100%;
  }

  .battle {
    margin-bottom: 8px;
  }

  .share {
    @media screen and (max-width: $screen-md) {
      display: none;
    }

    .socials {
      display: flex;
      align-items: center;

      a {
        margin-right: 20px;
      }
    }
  }

  .share-mobile {
    display: none;
    text-align: center;

    @media screen and (max-width: $screen-md) {
      display: block;
    }

    .socials {
      margin-top: 16px;

      img {
        width: 40px;
        height: 40px;
        margin: 0 25px;
      }
    }
  }

  .description {
    max-width: 420px;
    margin-top: 20px;
    width: 100%;
  }
}

.staff-row {
  margin-top: 20px;
}
</style>
