<template>
  <div>
    <UdefLoader v-if="loading.page" />
    <section v-else>
      <section class="profile-hero">
        <div class="overflow-hidden">
          <div
            :style="`background: center/ cover no-repeat url(${getAvatar(user.avatar, 200)})`"
            class="background"
          />
        </div>
        <div class="gradient" />
        <div class="udef-content">
          <div class="content">
            <div class="image-container">
              <img class="image" :src="getAvatar(user.avatar, 800, 1000)" alt="profile background image">
              <div class="rank">
                <div class="rank-wrapper">
                  <div class="rank-header">
                    <div v-if="$vuetify.breakpoint.mdAndUp" class="socials">
                      <a
                        v-if="user.socials.facebook"
                        target="_blank"
                        :href="`https://www.facebook.com/${user.socials.facebook}`"
                      >
                        <img src="/img/icons/facebook.svg" alt="facebook">
                      </a>
                      <a
                        v-if="user.socials.twitter"
                        target="_blank"
                        :href="`https://twitter.com/${user.socials.twitter}`"
                      >
                        <img src="/img/icons/twitter.svg" alt="twitter">
                      </a>
                      <a
                        v-if="user.socials.instagram"
                        target="_blank"
                        :href="`https://www.instagram.com/${user.socials.instagram}`"
                      >
                        <img src="/img/icons/instagram.svg" alt="instagram">
                      </a>
                      <a
                        v-if="user.socials.youtube"
                        target="_blank"
                        :href="`https://www.youtube.com/channel/${user.socials.youtube}`"
                      >
                        <img src="/img/icons/youtube.svg" alt="youtube">
                      </a>
                      <a
                        v-if="user.socials.website"
                        target="_blank"
                        :href="user.socials.website"
                      >
                        <img src="/img/icons/link.svg" alt="website">
                      </a>
                    </div>
                  </div>
                  <h1>{{ user.dancerName }}</h1>
                  <div>{{ user.fullName }} <span v-if="getFullAge">, {{ getFullAge }}</span></div>

                  <div class="location">
                    <span>{{ user.country }}</span>
                    <span v-if="user.city">, {{ user.city }}</span>
                  </div>

                  <div v-if="$vuetify.breakpoint.smAndDown" class="socials">
                    <a
                      v-if="user.socials.facebook"
                      target="_blank"
                      :href="`https://www.facebook.com/${user.socials.facebook}`"
                    >
                      <img src="/img/icons/facebook.svg" alt="facebook">
                    </a>
                    <a
                      v-if="user.socials.twitter"
                      target="_blank"
                      :href="`https://twitter.com/${user.socials.twitter}`"
                    >
                      <img src="/img/icons/twitter.svg" alt="twitter">
                    </a>
                    <a
                      v-if="user.socials.instagram"
                      target="_blank"
                      :href="`https://www.instagram.com/${user.socials.instagram}`"
                    >
                      <img src="/img/icons/instagram.svg" alt="instagram">
                    </a>
                    <a
                      v-if="user.socials.youtube"
                      target="_blank"
                      :href="`https://www.youtube.com/channel/${user.socials.youtube}`"
                    >
                      <img src="/img/icons/youtube.svg" alt="youtube">
                    </a>
                    <a
                      v-if="user.socials.website"
                      target="_blank"
                      :href="user.socials.website"
                    >
                      <img src="/img/icons/link.svg" alt="website">
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="stats">
              <div class="stats-container">
                <div class="stat">
                  <div class="key">
                    <UdefIcon medium color="#666666" icon="rank" />
                    <span>Points</span>
                  </div>

                  <!-- TODO: Real badge values -->
                  <div class="value">
                    {{ user.organizationScore.points }}
                  </div>
                </div>

                <div class="stat">
                  <div class="key">
                    <UdefIcon medium color="#666666" icon="battle-flags" />
                    <span>Battles Entered</span>
                  </div>
                  <div class="value">
                    {{ user.battlesEntered }}
                  </div>
                </div>

                <div class="stat">
                  <div class="key">
                    <UdefIcon medium color="#666666" icon="battle-trophy" />
                    <span>Battles Won</span>
                  </div>
                  <div class="value">
                    {{ user.battlesWon }}
                  </div>
                </div>

                <div class="stat">
                  <div class="key">
                    <UdefIcon medium color="#666666" icon="disc" />
                    <span>Events DJ'd</span>
                  </div>
                  <div class="value">
                    {{ user.eventsDj }}
                  </div>
                </div>

                <div class="stat">
                  <div class="key">
                    <UdefIcon medium color="#666666" icon="judge" />
                    <span>Battles Judged</span>
                  </div>
                  <div class="value">
                    {{ user.battlesJudged }}
                  </div>
                </div>

                <div class="stat">
                  <div class="key">
                    <UdefIcon medium color="#666666" icon="calendar" />
                    <span>Events Attended</span>
                  </div>
                  <div class="value">
                    {{ user.eventsAttended }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="sort-section udef-content">
        <div class="types">
          <div
            :class="{ active: period ==='future' }"
            class="type"
            @click="searchParams.timeFilter = 'future'"
          >
            Upcoming
          </div>
          <div
            :class="{ active: period === 'past' }"
            class="type"
            @click="searchParams.timeFilter = 'past'"
          >
            Past
          </div>
        </div>
        <div class="sort-container">
          <v-menu
            :close-on-content-click="false"
            class="sort-menu"
            nudge-width="242"
            offset-y
          >
            <template #activator="{ on }">
              <div class="filter-wrapper" v-on="on">
                <UdefIcon
                  :big="$vuetify.breakpoint.mdAndDown"
                  class="filter"
                  medium
                  icon="filter"
                />
              </div>
            </template>

            <v-list class="filter-container">
              <v-list-item>
                <v-list-item-title class="filter-title">
                  Filter by
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-for="(filterOption, index) in filterOptions"
                :key="index"
              >
                <v-list-item-title
                  class="filter-option"
                >
                  <div class="d-flex align-center">
                    <UdefIcon class="mr-2" :icon="filterOption.icon" />
                    {{ filterOption.name }}
                  </div>
                  <v-checkbox
                    v-model="filterOption.value"
                    class="filter-checkbox"
                    color="secondary"
                    hide-details
                  />
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <div class="d-flex">
            <UdefSorting class="sort" :sorting-options="sortingOptions" @change="changeSorting($event)" />
            <div class="arrows" @click="sortDirection = !sortDirection">
              <v-icon class="arrow-up">
                mdi-chevron-up
              </v-icon>

              <v-icon class="arrow-down">
                mdi-chevron-down
              </v-icon>
            </div>
          </div>
        </div>
      </section>

      <div class="udef-content events">
        <UdefLoader v-if="loading.events" />
        <section v-else>
          <UdefEventCardHorizontal
            v-for="(event,index) in events"
            :key="index"
            class="event"
            :event="event"
          />
        </section>

        <InfiniteLoading :identifier="infiniteId" @infinite="loadEvents">
          <div slot="spinner">
            <UdefLoader />
          </div>
          <div slot="no-more" />
          <div slot="no-results" />
        </InfiniteLoading>
      </div>
    </section>
  </div>
</template>

<script>
import api from '@/api'

import Image from '@/mixins/image'
import UdefEventCardHorizontal from '@/components/UdefEventCardHorizontal'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'Profile',
  components: {
    UdefEventCardHorizontal,
    InfiniteLoading
  },
  mixins: [Image],
  data() {
    return {
      infiniteId: +new Date(),
      loading: { page: true, events: false },
      enoughLoadEvents: false,
      user: null,
      sortDirection: true,
      filterOptions: [
        { name: 'Competing', value: false, icon: 'battle-flags' },
        { name: 'Spectating', value: false, icon: 'user' }
      ],
      searchParams: {
        pageNumber: 0,
        pageSize: 8,
        timeFilter: 'future',
        filter: '',
        sortBy: 'date',
        sortDir: 'desc',
        userId: +this.$route.params.id
      },
      events: [],
      sortingOptions: [
        { name: 'date', label: 'Date' },
        { name: 'title', label: 'A-Z' }
      ]
    }
  },
  computed: {
    getFullAge() {
      if (!this.user.id || !this.user.birthday) { return }

      const birthdayDate = new Date(this.user.birthday)
      const ageDiffInMs = Date.now() - birthdayDate.getTime()
      const ageDate = new Date(ageDiffInMs)
      return Math.abs(ageDate.getUTCFullYear() - new Date(0).getUTCFullYear())
    },
    period() {
      return this.searchParams.timeFilter
    }
  },
  watch: {
    async period() {
      await this.reloadEvents()
    },
    async sortDirection(value) {
      this.searchParams.sortDir = value ? 'asc' : 'desc'
      await this.reloadEvents()
    },
    filterOptions: {
      async handler() {
        if (
          this.filterOptions.every(option => option.value === false) ||
          this.filterOptions.every(option => option.value === true)
        ) {
          this.searchParams.filter = ''
          await this.reloadEvents()
          return
        }

        this.filterOptions.forEach(option => {
          if (option.value) {
            this.searchParams.filter = option.name.toLowerCase()
          }
        })

        await this.reloadEvents()
      },
      deep: true
    }
  },
  async mounted() {
    this.user = await api.users.getUser(this.$route.params.id)
    this.loading.page = false
  },
  methods: {
    async loadEvents($state) {
      this.enoughLoadEvents = true
      const events = await api.events.getEvents(this.searchParams)

      if (events.length) {
        this.events = [...this.events, ...events]
      }

      if (events.length === this.searchParams.pageSize) {
        this.searchParams.pageNumber += 1
        $state.loaded()
        return
      }

      $state.complete()
    },
    async reloadEvents() {
      window.scrollTo({ top: 0 })
      this.loading.events = true
      this.searchParams.pageNumber = 0
      this.events = []
      this.infiniteId += 1
      this.loading.events = false
    },
    async changeSorting(field) {
      if (field === 'title') this.searchParams.sortDir = 'asc'
      this.searchParams.sortBy = field
      await this.reloadEvents()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/style/global.scss';

.filter-container .v-list-item__title.filter-option {
  cursor: pointer;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-container .v-list-item__title.filter-title {
  font-weight: $font-weight-bold;
}
</style>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.event {
  margin-bottom: 8px;
}

.profile-hero {
  position: relative;

  .background {
    min-height: 579px;
    filter: blur(60px);

    @media screen and (max-width: $screen-md) {
       display: none;
    }
  }

  .gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.21) 0%, rgba(0, 0, 0, 0.15) 100%);
    position: absolute;
    min-height: 579px;
    top: 0;
    width: 100%;

    @media screen and (max-width: $screen-md) {
      display: none;
    }
  }
}

.content {
  max-width: 1100px;
  width: 100%;
  position: absolute;
  top: 0;
  box-shadow: $article-shadow;
  margin: 40px 165px;
  display: flex;

  @media screen and (max-width: $screen-lg) {
    margin: 40px 0;
  }

  @media screen and (max-width: $screen-md) {
    margin: 0;
    position: unset;
    display: flex;
    flex-direction: column;
  }

  .image-container {
    position: relative;

    .image {
      box-shadow: $article-shadow;
      width: 421px;
      height: 100%;
      display: block;

      @media screen and (max-width: $screen-md) {
        width: 100%;
      }
    }

    .rank {
      position: absolute;
      max-width: 420px;
      padding: 20px;
      width: 100%;
      background-color: $white;
      box-shadow: $article-shadow;
      bottom: -48px;
      left: -25px;

      .rank-wrapper {
        position: relative;
      }

      .rank-number {
        font-weight: $font-weight-bold;
      }

      .rank-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .trophy {
        opacity: 0.6;
        margin-right: 8px;
      }

      .location {
        color: $grey;
        font-size: $font-small;
      }

      @media screen and (max-width: $screen-md) {
        position: sticky;
        max-width: 100%;
        width: 90%;
        margin: -10px auto 0 auto;
      }
    }

    .socials {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @media screen and (max-width: $screen-md) {
        margin: 0 -22px;
      }

      img {
        margin: 0 8px;
        width: 24px;
        height: 24px;
        @media screen and (max-width: $screen-md) {
          margin: 15px 22px;
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .stats {
    background-color: $white;
    max-width: 690px;
    width: 100%;

    @media screen and (max-width: $screen-md) {
      max-width: unset;
    }
  }
}

.stats-container {
  padding: 60px;

  @media screen and (max-width: $screen-md) {
    padding: 25px 0;
  }

  .stat {
    display: flex;
    justify-content: space-between;
    padding: 20px 27px;
    border-bottom: 1px solid $light;

    .key {
      display: flex;

      span {
        margin-left: 14px
      }
    }
  }
}

.sort-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 90px 50px 45px 50px;

  @media screen and (max-width: $screen-md) {
    padding: 27px 20px;
  }

  .types {
    display: flex;
    column-gap: 26px;
  }

  .type {
    cursor: pointer;
    padding-bottom: 4px;

    &.active {
      font-weight: $font-weight-bold;
      border-bottom: 2px solid $black-light;
    }
  }

  .sort-container {
    display: flex;
    align-items: center;

    @media screen and (max-width: $screen-md) {
      margin-top: 30px;
      width: 100%;
      justify-content: space-between;
    }
  }

  .filter {
    margin-right: 20px;
    margin-bottom: -7px;
    cursor: pointer;
  }

  .arrows {
    width: 20px;
    margin-left: 10px;
    position: relative;
    cursor: pointer;

    button {
      height: 12px;
    }

    button:focus::after {
      opacity: 0;
    }

    i {
      color: $black-light
    }

    .arrow-up,
    .arrow-down {
      position: absolute;
    }

    .arrow-up {
      top: -6px;
    }

    .arrow-down {
      bottom: -6px;
    }
  }
}

.events {
  min-height: 130px;
  padding: 0 50px;

  @media screen and (max-width: $screen-md) {
    padding: 0;
  }
}

.filter-checkbox {
  margin: 0;
}
</style>
