import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/pages/Home.vue'
import About from '@/pages/About.vue'
import Articles from '@/pages/Articles.vue'
import Article from '@/pages/Article.vue'
import Events from '@/pages/Events.vue'
import Event from '@/pages/Event.vue'
import Leaderboard from '@/pages/Leaderboard.vue'
import Profile from '@/pages/Profile.vue'
import Media from '@/pages/Media.vue'
import PrivacyPolicy from '@/pages/PrivacyPolicy.vue'
import Legal from '@/pages/Legal.vue'
import Terms from '@/pages/Terms.vue'
import DonationThank from '@/pages/DonationThank.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/events',
    name: 'Events',
    component: Events
  },
  {
    path: '/event/:id',
    name: 'Event',
    component: Event
  },
  {
    path: '/news',
    name: 'Articles',
    component: Articles
  },
  {
    path: '/article/:id',
    name: 'Article',
    component: Article
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: Leaderboard
  }, {
    path: '/profile/:id',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/media',
    name: 'Media',
    component: Media
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/legal-and-tax-information',
    name: 'Legal',
    component: Legal
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/donation-thank',
    name: 'DonationThank',
    component: DonationThank
  }
]

const scrollBehavior = (to, from, savedPosition) => {
  return { x: 0, y: 0 }
}

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior
})

export default router
