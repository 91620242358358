<template>
  <article class="chip">
    <v-avatar class="avatar" :size="small ? 32 : 48">
      <v-img :src="getAvatar(user.avatar, 96)" />
    </v-avatar>
    <div v-if="!hideName" class="name">
      {{ user.dancerName }}
    </div>
  </article>
</template>

<script>
import Images from '@/mixins/image'

export default {
  name: 'UdefUserChip',
  mixins: [Images],
  props: {
    user: {
      type: Object,
      required: true
    },
    hideName: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.chip {
  display: flex;
  align-items: center;

  .avatar {
    margin-right: 8px;
  }

  .name {
    color: $grey-light;
  }
}
</style>
