<template>
  <div>
    <v-text-field
      v-model="inputValue"
      class="udef-input"
      color="secondary"
      background-color="white"
      tile
      outlined
      autocomplete="off"
      :error="error"
      :placeholder="placeholder"
      hide-details
    />
  </div>
</template>

<script>
export default {
  name: 'UdefInput',
  props: {
    value: {
      type: String,
      required: true
    },
    error: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      inputValue: this.value
    }
  },
  watch: {
    inputValue() {
      this.$emit('update:value', this.inputValue)
    }
  }
}
</script>

<style lang="scss">
.udef-input .v-input__control {
  border-radius: 0;
}
</style>
