<template>
  <section>
    <UdefHero img="/img/hero-media.webp">
      <div class="hero-title">
        <span>Media</span>
      </div>
    </UdefHero>

    <UdefLoader v-if="loading" />

    <div v-else class="udef-content">
      <section class="search">
        <UdefInput :value.sync="mediaSearch" placeholder="Search videos" />
      </section>

      <section v-if="media.length" class="clips">
        <h2 class="clips-title">
          UDEF Clips
        </h2>

        <div class="clips-grid">
          <UdefMediaCard
            v-for="(mediaItem, index) in media"
            :key="index"
            class="clip"
            :media="mediaItem"
            @show="showMedia"
          />
        </div>
      </section>
    </div>

    <UdefModal v-if="isShowMedia" :is-show.sync="isShowMedia">
      <v-card max-width="830" height="450" tile>
        <iframe
          width="100%"
          height="100%"
          :src="embedSourceUrl"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </v-card>
    </UdefModal>
  </section>
</template>

<script>
import api from '@/api'

import UdefMediaCard from '@/components/UdefMediaCard'
import UdefModal from '@/components/UdefModal'

export default {
  name: 'Media',
  components: {
    UdefMediaCard,
    UdefModal
  },
  data() {
    return {
      mediaSearch: '',
      media: [],
      isShowMedia: false,
      selectedMedia: '',
      loading: true,
      mediaSearchTimeout: null
    }
  },
  computed: {
    embedSourceUrl() {
      return `https://www.youtube.com/embed/${this.selectedMedia}?autoplay=1`
    }
  },
  watch: {
    isShowMedia(value) {
      if (!value) {
        this.selectedMedia = ''
      }
    },
    mediaSearch() {
      clearTimeout(this.mediaSearchTimeout)
      this.mediaSearchTimeout = setTimeout(async() => {
        await this.loadMedia()
      }, 600)
    }
  },
  async mounted() {
    await this.loadMedia()
    this.loading = false
  },
  methods: {
    showMedia(link) {
      this.isShowMedia = true
      this.selectedMedia = link
    },
    async loadMedia() {
      this.media = await api.media.getMedia(this.mediaSearch)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.hero-title {
  min-height: 650px;
  font-size: $font-hero-title;
  font-weight: $font-weight-bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;

  @media screen and (max-width: $screen-md) {
   min-height: 268px;
   font-size: $font-section-title-mobile;
  }
}

.search {
  padding: 80px 50px 0 50px;
  max-width: 410px;

  @media screen and (max-width: $screen-md) {
    padding: 40px 20px;
  }
}

.clips-title {
  padding: 40px 50px 0;
  font-size: $font-medium;
  font-weight: $font-weight-bold;

  @media screen and (max-width: $screen-md) {
    padding: 20px 20px 0;
  }
}

.clips-grid {
  padding: 0 50px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;

  @media screen and (max-width: $screen-md) {
    padding: 0 20px;
    margin: 0;
  }

  .clip {
    margin: 20px 20px 0;

    @media screen and (max-width: $screen-md) {
      margin: 20px auto 0;
    }
  }
}

</style>
