<template>
  <router-link
    tag="article"
    :to="{name: 'Profile', params: { id: user.id} }"
    :class="{ top: isUserInTop10 }"
    class="user"
  >
    <div class="content">
      <div class="place">
        <template v-if="user.rank">
          <img
          v-if="isUserInTop1"
          alt="place trophy"
          class="img"
          src="/img/icons/winner-top-1.svg"
        >
          <img
            v-else-if="isUserInTop10"
            alt="place trophy"
            class="img"
            src="/img/icons/winner-top-10.svg"
          >
          <img
            v-else
            alt="place trophy"
            class="img"
            src="/img/icons/winner-top-100.svg"
          >
          <span>{{ user.rank }}</span>
        </template>
      </div>
      <div class="username">
        <v-avatar class="img" :size="isUserInTop10 ? 48 : 32">
          <v-img :src="getAvatar(user.avatar, 100)" />
        </v-avatar>
        <div class="name">
          <span>{{ user.dancerName }}</span>
          <div class="stats-mobile">
            <div class="country-mobile">
              <v-avatar class="img" size="20">
                <v-img :src="getFlag(user.country)" />
              </v-avatar>
              <div class="name">
                {{ user.country }}
              </div>
            </div>

            <div class="rank-mobile">
              <div class="points">
                {{ user.points }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="country">
        <v-avatar class="img" size="20">
          <v-img :src="getFlag(user.country)" />
        </v-avatar>
        <div class="name">
          {{ user.country }}
        </div>
      </div>
      <div class="rank">
        <div class="points">
          {{ user.points }}
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import Images from '@/mixins/image'

export default {
  name: 'UdefUserCardHorizontal',
  mixins: [Images],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    isUserInTop1() {
      return this.user.rank === 1
    },
    isUserInTop10() {
      return this.user.rank > 0 && this.user.rank <= 10
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.user {
  color: $black-light;
  font-size: $font-medium;
  cursor: pointer;

  &.top {
    box-shadow: $horizontal-card-shadow;
    font-size: $font-big;
  }
}

.content {
  max-width: 810px;
  width: 100%;
  margin: auto;
  display: flex;
  align-items:center;
  padding: 22px 0;

  @media screen and (max-width: $screen-md) {
    padding: 22px 20px;
  }
}

.place,
.rank,
.username,
.country,
.rank {
  display: flex;
  align-items: center;
}

.place {
  max-width: 78px;
  width: 100%;

  .img {
    margin-right: 16px;
    height: 24px;
    width: 24px;
  }
}

.username {
  max-width: 350px;
  width: 100%;

  .img {
    margin-right: 16px;
  }

  .name {
    width: 100%;
  }
}

.country {
  max-width: 275px;
  width: 100%;

  @media screen and (max-width: $screen-md) {
    display: none;
  }

  .img {
    margin-right: 5px;
  }
}

.rank {
  max-width: 93px;
  width: 100%;
  justify-content: flex-end;

  @media screen and (max-width: $screen-md) {
    display: none;
  }
}

.stats-mobile {
  display: none;
  justify-content: space-between;

  @media screen and (max-width: $screen-md) {
   display: flex;
  }

  .country-mobile {
    display: flex;
    align-items: center;
  }
}
</style>
