<template>
  <section v-if="!loading && article" class="article">
    <v-img class="image" :src="article.image" />

    <div class="information">
      <h1 class="article-title">
        {{ article.title }}
      </h1>

      <div class="author">
        <article class="chip">
          <v-avatar class="avatar" :size="24">
            <v-img :src="article.author.avatar" />
          </v-avatar>
          <div class="name">
            {{ article.author.name }}
          </div>
        </article>
      </div>

      <div class="date">
        {{ article.date }}
      </div>
    </div>

    <div class="article-content" v-html="article.content" />
    <div class="share">
      Share the article
      <div class="socials">
        <a target="_blank" :href="facebookShareUrl">
          <img src="/img/socials/facebook-grey.svg" alt="facebook">
        </a>
        <a target="_blank" :href="twitterShareUrl">
          <img src="/img/socials/twitter-grey.svg" alt="twitter">
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import Dates from '@/mixins/date'
import api from '@/api'
import { DEFAULT_IMAGES } from '@/common/constants'
import Images from '@/mixins/image'

export default {
  name: 'Article',
  mixins: [Dates, Images],
  data() {
    return {
      loading: true,
      article: {}
    }
  },
  computed: {
    articleUrl() {
      return window.location.href
    },
    twitterShareUrl() {
      const twitterUrl = 'https://twitter.com/intent/tweet'
      const shareUrl = `${twitterUrl}?text=${this.article.title}&url=${this.articleUrl}`
      return shareUrl
    },
    facebookShareUrl() {
      const facebookUrl = 'https://www.facebook.com/sharer/sharer.php'
      const shareUrl = `${facebookUrl}?u=${encodeURI(this.articleUrl)}`
      return shareUrl
    }
  },
  async mounted() {
    const article = await api.articles.getArticle(this.$route.params.id)
    if (!article) {
      this.article = null
      return
    }
    
    const articleImage = article.images 
      ? article.images[0].url
      : DEFAULT_IMAGES.article

    this.article = {
      image: articleImage,
      title: article.title,
      content: this.getArticleTotalContent(article.content),
      date: this.getDate(article.published),
      author: {
        avatar: article.author.image.url,
        name: article.author.displayName
      }
    }
    this.loading = false
  },
  methods: {
    getArticleTotalContent(content) {
      const totalContent = document.createElement('div')
      totalContent.innerHTML = content
      const allImages = totalContent.querySelectorAll('img')
      allImages.forEach((image, index) => {
        if (index === 0) {
          image.parentNode.removeChild(image)
        }

        image.classList.add('article-image')
      })
      return totalContent.innerHTML
    }
  }
}
</script>

<style lang="scss">
.article .article-content {
  .article-image {
    display: block;
    max-width: 100%;
    height:unset;
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.article {
  margin: 80px auto;
  max-width: 650px;
  width: 100%;

  @media screen and (max-width: $screen-sm) {
    margin: 0;
    max-width: unset;
  }
}

.image {
  display: block;
  height: 650px;
  width: 650px;

  @media screen and (max-width: $screen-sm) {
    height: unset;
    width: 100%;
  }
}

.information {

  @media screen and (max-width: $screen-sm) {
    padding: 0 20px;
  }
}

.article-title {
  color: $black-light;
  font-size: $font-section-title;
  line-height: 64px;
  margin-top: 20px;

  @media screen and (max-width: $screen-sm) {
    font-size: $font-section-title-mobile;
    line-height: 40px;
  }
}

.chip {
  margin-top: 20px;
  display: flex;
  align-items: center;

  .avatar {
    margin-right: 8px;
  }

  .name {
    color: $grey-light;
  }
}

.date {
  margin-top: 8px;
  color: $grey-light;
}

.article-content {
  margin-top: 20px;
  color: $dark;

  @media screen and (max-width: $screen-sm) {
    padding: 0 20px;
  }
}

.share {
  margin: 40px 0;
  color: $grey-light;
  width: 100%;
  text-align: center;
}

.socials {
  margin-top: 10px;
  color: $grey-light;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 20px;
  }
}

</style>
