<template>
  <v-footer
    absolute
    app
    height="450"
    tag="footer"
  >
    <section class="content udef-content">
      <div class="udef">
        <div class="logo">
          <v-img class="img" src="/img/logo.svg" />
          <p class="desc">
            © Copyright {{ currentYear }}
          </p>
        </div>
        <div class="information">
          <div class="socials">
            <a
              target="_blank"
              href="https://www.instagram.com/myudef/"
            ><img src="/img/socials/instagram-red.svg" alt="instagram"></a>
            <a
              target="_blank"
              href="https://www.facebook.com/myudef"
            > <img src="/img/socials/facebook-red.svg" alt="facebook"></a>
            <a
              target="_blank"
              href="https://twitter.com/joinudef"
            ><img src="/img/socials/twitter-red.svg" alt="twitter"></a>
            <a
              target="_blank"
              href="https://www.youtube.com/udeftour/"
            ><img src="/img/socials/youtube-red.svg" alt="youtube"></a>
          </div>

          <div class="docs">
            <router-link
              :to="{name: 'Legal'}"
              class="link"
            >
              Legal and Tax Information
            </router-link>
            <router-link
              :to="{name: 'Terms'}"
              class="link"
            >
              Terms and Conditions
            </router-link>
            <router-link
              :to="{name: 'PrivacyPolicy'}"
              class="link"
            >
              Privacy Policy
            </router-link>
          </div>
        </div>
      </div>

      <div class="separator" />

      <div class="partners">
        <a target="_blank" href="https://www.probreakingtour.com/">
          <img src="/img/partners/pro-breaking.svg" alt="pro breaking" class="partner">
        </a>
        <a target="_blank" href="https://www.redbull.com/int-en/event-series/bc-one">
          <img src="/img/partners/redbull.svg" alt="redbull" class="partner">
        </a>
        <a target="_blank" href="https://www.jackinthebox.com">
          <img src="/img/partners/jib.svg" alt="jib" class="partner">
        </a>
        <a target="_blank" href="https://www.silverbackopen.com/">
          <img src="/img/partners/silverback.svg" alt="silverback" class="partner">
        </a>
        <a target="_blank" href="https://breakkonnect.com/">
          <img src="/img/partners/BK.svg" alt="breakkonect" class="partner">
        </a>
      </div>
    </section>
  </v-footer>
</template>

<script>
export default {
  name: 'TheFooter',
  data() {
    return {
      currentYear: new Date().getFullYear(),
      isShowMenu: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

footer {
  background-color: $ultra-light !important;
}

.content {
  padding: 75px 50px;
  width: 100%;

  @media screen and (max-width: $screen-sm) {
   padding: 50px 20px;
  }
}

.udef {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: $screen-sm) {
    flex-direction: column;
  }
}

.logo {
  .img {
    width: 148px;
    height: 40px;
  }

  .desc {
    color: $grey;
    margin: 0;
    margin-top: 30px
  }
}

.information {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $screen-sm) {
    flex-direction: column-reverse;
  }

  .socials {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: $screen-sm) {
      justify-content: flex-start;
    }

    img {
      margin-right: 29px;
      fill: red;
    }
  }

  .docs {
    margin-top: 40px;
    font-weight: $font-weight-bold;

    .link {
      margin-right: 10px;

      @media screen and (max-width: $screen-sm) {
        display: block;
        margin-bottom: 20px;
     }
    }
  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: $grey;
  margin-top: 38px;
  margin-bottom: 40px;
}

.partners {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;

  @media screen and (max-width: $screen-sm) {
    flex-direction: row-reverse;
    justify-content: space-around;
  }

  a {
    @media screen and (max-width: $screen-sm) {
     width: 30%;
     display: block;
     margin-bottom: 10px;
    }
  }

  img {
    width: 100%;
  }
}

</style>
