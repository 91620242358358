<template>
  <div class="header-container">
    <div class="header udef-content">
      <div class="d-flex align-center">
        <img
          alt="menu"
          class="menu-icon"
          src="/img/icons/menu-hamburger.svg"
          @click="isMenuOpen=!isMenuOpen"
        >
        <router-link :to="{name: 'Home'}" class="logo" @click.native="isMenuOpen=false">
          <img class="logo-img" src="/img/logo.svg">
        </router-link>
      </div>
      <nav class="nav-items">
        <router-link :to="{name: 'About'}" class="item">
          About
        </router-link>
        <router-link :to="{name: 'Leaderboard'}" class="item">
          Leaderboard
        </router-link>
        <router-link :to="{name: 'Events'}" class="item">
          Events
        </router-link>
        <router-link :to="{name: 'Media'}" class="item">
          Media
        </router-link>
        <router-link :to="{name: 'Articles'}" class="item">
          News
        </router-link>
        <a target="_blank" href="https://www.paypal.com/donate/?hosted_button_id=F6RT4N3ZJQK7S" class="item">
          Donate
        </a>
      </nav>
      <!-- <div class="user-chip" /> -->
      <div>
        <UdefButton
          v-if="$vuetify.breakpoint.mdAndUp"
          :href="`${breakkonectUrl}/signup`"
          small-text
          width="307"
          color="primary"
        >
          Create a profile on Break Konnect
        </UdefButton>
      </div>
    </div>
    <div :class="{ 'open': isMenuOpen }" class="mobile-nav">
      <nav class="mobile-nav-items">
        <router-link :to="{name: 'About'}" class="item" @click.native="isMenuOpen=false">
          About
        </router-link>
        <router-link :to="{ name: 'Leaderboard' }" class="item" @click.native="isMenuOpen=false">
          Leaderboard
        </router-link>
        <router-link :to="{name: 'Events'}" class="item" @click.native="isMenuOpen=false">
          Events
        </router-link>
        <router-link :to="{name: 'Media'}" class="item" @click.native="isMenuOpen=false">
          Media
        </router-link>
        <router-link :to="{name: 'Articles'}" class="item" @click.native="isMenuOpen=false">
          News
        </router-link>
        <a target="_blank" href="https://www.paypal.com/donate/?hosted_button_id=F6RT4N3ZJQK7S" class="item">
          Donate
        </a>
        <div class="mobile-btn">
          <UdefButton
            v-if="$vuetify.breakpoint.smAndDown"
            :href="`${breakkonectUrl}/signup`"
            small-text
            width="100%"
            color="primary"
            height="52"
          >
            Create a profile on Break Konnect
          </UdefButton>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheHeader',
  data() {
    return {
      isMenuOpen: false,
      breakkonectUrl: process.env.VUE_APP_BREAKKONECT_URL
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.header {
  padding: 20px 50px;
  height: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $screen-md) {
    padding: 20px;
  }
}

.menu-icon {
  height: 24px;
  width: 24px;
  display: none;
  margin-right: 12px;
  cursor: pointer;

  @media screen and (max-width: $screen-md) {
    display: block;
  }
}

.logo {
  margin-top: 6px;

  .logo-img {
    width: 148px;
    height: 40px;

    @media screen and (max-width: $screen-md) {
      width: 62px;
      height: 16px;
    }
  }
}

.nav-items {
  @media screen and (max-width: $screen-md) {
   display: none;
  }
  .item {
    font-weight: $font-weight-bold;
    margin-right: 10px;
    color: $black-light
  }
}

.mobile-nav {
  position: absolute;
  top: 82px;
  background-color: $white;
  z-index: 2;
  width: 100%;
  display: none;

  &.open {
    display: block
  }
}

.mobile-nav-items {
  .item {
    display: block;
    padding: 10px 20px;
    color: $black-light;
    font-weight: $font-weight-bold;
  }

  .mobile-btn {
    margin: 20px;
  }
}
</style>
