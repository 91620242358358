<template>
  <div>
    <UdefLoader v-if="loading" />
    <section v-else>
      <div class="udef-content">
        <section v-if="featureArcticle" class="featured">
          <v-img
            class="image"
            :src="featureArcticle.image"
            alt="article"
          />
          <div class="content">
            <h1>{{ featureArcticle.title }}</h1>
            <div class="author">
              <article class="chip">
                <v-avatar class="avatar" :size="24">
                  <v-img :src="featureArcticle.author.avatar" />
                </v-avatar>
                <div class="name">
                  {{ featureArcticle.author.name }}
                </div>
              </article>
            </div>
            <div class="date">
              {{ featureArcticle.date }}
            </div>

            <div class="text">
              {{ featureArcticle.text }}
            </div>
            <div class="actions">
              <div class="share">
                Share the article
                <div class="socials">
                  <a target="_blank" :href="facebookShareUrl">
                    <img src="/img/socials/facebook-grey.svg" alt="facebook">
                  </a>
                  <a target="_blank" :href="twitterShareUrl">
                    <img src="/img/socials/twitter-grey.svg" alt="twitter">
                  </a>
                </div>
              </div>
              <UdefButton
                :to="{name: 'Article', params: { id: featureArcticle.id} }"
                class="read-btn"
                color="primary"
                :width="$vuetify.breakpoint.mdAndDown ? '100%' : '190'"
                height="52"
              >
                Read article
              </UdefButton>
            </div>
          </div>
        </section>
      </div>

      <div class="udef-content">
        <section class="search">
          <UdefInput :value.sync="articlesSearch" placeholder="Search news" />
        </section>

        <div class="articles">
          <UdefArticleCard
            v-for="(article, index) in articles"
            :key="index"
            class="article"
            :article="article"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from '@/api'
import Truncate from '@/mixins/truncate'
import Dates from '@/mixins/date'
import { DEFAULT_IMAGES } from '@/common/constants'
import UdefArticleCard from '@/components/UdefArticleCard'
import Images from '@/mixins/image'

export default {
  name: 'News',
  components: {
    UdefArticleCard
  },
  mixins: [Truncate, Dates, Images],
  data() {
    return {
      loading: true,
      articlesSearch: '',
      articlesSearchTimeout: null,
      articles: [],
      featureArcticle: null
    }
  },
  computed: {
    articleUrl() {
      const articleUrl = `${window.location.origin}/article/${this.featureArcticle.id}`
      return articleUrl
    },
    twitterShareUrl() {
      const twitterUrl = 'https://twitter.com/intent/tweet'
      const shareUrl = `${twitterUrl}?text=${this.featureArcticle.title}&url=${this.articleUrl}`
      return shareUrl
    },
    facebookShareUrl() {
      const facebookUrl = 'https://www.facebook.com/sharer/sharer.php'
      const shareUrl = `${facebookUrl}?u=${encodeURI(this.articleUrl)}`
      return shareUrl
    }
  },
  watch: {
    articlesSearch(keyword) {
      clearTimeout(this.articlesSearchTimeout)
      this.articlesSearchTimeout = setTimeout(async() => {
        const resp = await api.articles.searchArticles(keyword)
        if (resp) {
          this.articles = resp.items ? resp.items.filter(article => article.id !== this.featureArcticle.id) : []
        }
      }, 600)
    }
  },
  async mounted() {
    const resp = await api.articles.getArticles()
    if (resp) {
      this.articles = resp.items
      const [firstArticle] = this.articles
      this.articles.shift()

      const htmlText = document.createElement('span')
      htmlText.innerHTML = firstArticle.content
      const featuredArticleText = htmlText.textContent

      const firstImage = htmlText.querySelector('img')
      const featuredArticleImage = firstImage 
        ? firstImage.src
        : DEFAULT_IMAGES.article

      this.featureArcticle = {
        id: firstArticle.id,
        title: this.getStringTruncated(firstArticle.title, 60),
        date: this.getDate(firstArticle.published),
        text: this.getStringTruncated(featuredArticleText, 185),
        image: featuredArticleImage,
        author: {
          name: firstArticle.author.displayName,
          avatar: firstArticle.author.image.url
        }
      }
    }

    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.featured {
  color: $black-light;
  box-shadow: $article-shadow;
  margin: 40px 50px;
  display: flex;

  @media screen and (max-width: $screen-lg) {
    margin: 40px 0;
  }

  @media screen and (max-width: $screen-md) {
    flex-direction: column;
    margin: 0;
  }

  .image {
    width: 100%;
    max-width: 650px;

    @media screen and (max-width: $screen-lg) {
      max-width: unset;
    }
  }

  .chip {
    display: flex;
    align-items: center;

    .avatar {
      margin-right: 8px;
    }

    .name {
      color: $grey-light;
    }
  }

  .content {
    color: $black-light;
    max-width: 690px;
    width: 100%;
    padding: 80px 40px 40px 40px;

    @media screen and (max-width: $screen-lg) {
      max-width: unset;
    }

    h1 {
      font-size: $font-section-title;
      line-height: 64px;

      @media screen and (max-width: $screen-md) {
        font-size: $font-section-title-mobile;
        line-height: 40px;
      }
    }

    .date {
      margin-top: 8px;
      color: $grey-light;
    }

    .author,
    .text {
      margin-top: 20px;
    }

    .text {
      min-height: 75px;
    }

    .actions {
      display: flex;
      flex-wrap: wrap;
      margin-top: 120px;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: $screen-md) {
        margin-top: 20px;
      }

      .read-btn {
        @media screen and (max-width: $screen-md) {
          margin-top: 20px;
        }
      }
    }

    .share {
      color: $grey-light;
      @media screen and (max-width: $screen-md) {
        width: 100%;
        text-align: center;
      }
    }

    .socials {
      margin-top: 10px;
      color: $grey-light;
      display: flex;
      align-items: center;

      @media screen and (max-width: $screen-md) {
        justify-content: center;
      }

      img {
        margin-right: 20px;
        @media screen and (max-width: $screen-md) {
          margin: 20px;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

.search {
  padding: 40px 50px 0 50px;
  max-width: 410px;
}

.articles {
  padding: 40px 50px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;

  @media screen and (max-width: $screen-md) {
    justify-content: center;
    padding: 20px;
  }

  .article {
    margin: 20px
  }
}
</style>
