<template>
  <div>
    <UdefHero img="/img/hero-about.webp">
      <div class="hero-title">
        <span>Unify to liberate</span>
      </div>
    </UdefHero>
    <AboutStory />
    <AboutPeoplesBehind />
  </div>
</template>

<script>
import AboutStory from '@/components/AboutStory'
import AboutPeoplesBehind from '@/components/AboutPeoplesBehind'

export default {
  name: 'About',
  components: {
    AboutStory,
    AboutPeoplesBehind
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.hero-title {
  min-height: 650px;
  font-size: $font-hero-title;
  font-weight: $font-weight-bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;

  @media screen and (max-width: $screen-md) {
   min-height: 268px;
   font-size: $font-section-title-mobile;
  }
}
</style>
