import ax from 'axios'

// External
const externalApi = ax.create()

externalApi.interceptors.response.use(
  response => response,
  (error) => {
    if (error && error.response && error.response.status !== 404) {
      // TODO: Need error notification
    }

    return Promise.reject(error)
  }
)

// Public Breakkonect API
const publicBKApi = ax.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

publicBKApi.interceptors.response.use(
  response => response,
  (error) => {
    if (error && error.response && error.response.status !== 404) {
      // TODO: Need error notification
    }

    return Promise.reject(error)
  }
)
export { externalApi, publicBKApi }
