<template>
  <v-btn
    :target="href ? '_blank': '_self'"
    :width="width"
    :height="height"
    elevation="0"
    :outlined="outlined"
    :text="text"
    :icon="icon"
    :color="color"
    :to="to"
    :href="href"
    :disabled="disabled"
    :dark="dark"
    :loading="loading"
    tile
    @click="$emit('click')"
  >
    <span :class="{small: smallText}" class="button-text"><slot /></span>
  </v-btn>
</template>

<script>
export default {
  name: 'UdefButton',
  props: {
    loading: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    smallText: { type: Boolean, default: false },
    dark: { type: Boolean, default: false },
    icon: { type: Boolean, default: false },
    text: { type: Boolean, default: false },
    color: { type: String, default: '' },
    to: { type: Object, default: null },
    href: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    width: { type: String, default: '' },
    height: { type: String, default: '' }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.button-text {
  line-height: 1;
  font-weight: $font-weight-bold;
  text-transform: none;
}

.small {
  font-size: $font-small;
}
</style>
