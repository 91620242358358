<template>
  <v-app class="app">
    <TheHeader />
    <v-main class="overflow-hidden">
      <router-view class="router-view" />
    </v-main>
    <TheFooter />
  </v-app>
</template>

<script>
import TheHeader from '@/components/TheHeader'
import TheFooter from '@/components/TheFooter'

export default {
  components: { TheHeader, TheFooter }
}
</script>

<style lang="scss">
@import '@/assets/style/global.scss';
@import '@/assets/style/style.scss';
@import url("https://use.typekit.net/vlh2atp.css");

.app {
  font-family: franklin-gothic-atf, sans-serif;
  min-height: 100vh;
}
</style>
