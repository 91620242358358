<template>
  <article class="battle" @click="$emit('click')">
    <div class="battle-title">
      {{ battle.title }}
    </div>
    <div class="size">
      {{ size }}
    </div>
  </article>
</template>

<script>
import { BATTLE_CATEGORIES } from '@/common/constants'

export default {
  name: 'UdefBattleCard',
  props: {
    battle: {
      type: Object,
      required: true
    }
  },
  computed: {
    size() {
      const crewSize = BATTLE_CATEGORIES.find(category => category.value === this.battle.crewSize)
      return crewSize.text
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.battle {
  cursor: pointer;
  padding: 20px;
  box-shadow: $horizontal-card-shadow;
  width: 100%;

  .battle-title {
    font-size: $font-big;
    font-weight: $font-weight-bold;
  }
}
</style>
