const BATTLE_CATEGORIES = [
  { text: '1 vs 1', value: 1 },
  { text: '2 vs 2', value: 2 },
  { text: '3 vs 3', value: 3 },
  { text: '4 vs 4', value: 4 },
  { text: '5 vs 5', value: 5 },
  { text: '6 vs 6', value: 6 },
  { text: '7 vs 7', value: 7 },
  { text: '8 vs 8', value: 8 },
  { text: '9 vs 9', value: 9 },
  { text: '10 vs 10', value: 10 },
  { text: 'Unlimited', value: 10000 }
]
Object.freeze(BATTLE_CATEGORIES)

const DEFAULT_IMAGES = {
  avatar: '/img/defaults/default-user.webp',
  article: '/img/defaults/default-article.png',
  default: '/img/defaults/default-image.svg'
}
Object.freeze(DEFAULT_IMAGES)

const BATTLE_ROLES = {
  judge: 'judge',
  dj: 'dj'
}
Object.freeze(BATTLE_ROLES)

export { BATTLE_CATEGORIES, DEFAULT_IMAGES, BATTLE_ROLES }
