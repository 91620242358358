<template>
  <div>
    <section>
      <UdefHero img="/img/hero-leaderboard.webp">
        <div class="hero-title">
          <span>Leaderboard</span>
        </div>
      </UdefHero>

      <div>
        <section class="sort-section">
          <div class="space" />
          <div class="search-container">
            <UdefInput :placeholder="`Search ${countUsers} members`" :value.sync="searchParams.keyword" />
          </div>
          <div class="options">
            <div class="view">
              <v-icon
                :class="{ active: !isGridView }"
                class="icon"
                @click="isGridView = false"
              >
                mdi-format-list-bulleted
              </v-icon>
              <v-icon
                :class="{ active: isGridView }"
                class="icon"
                @click="isGridView = true"
              >
                mdi-view-grid
              </v-icon>
            </div>
            <div class="sort-filter-wrap">
              <FilterByYear :year="searchParams.year" @update:year="filterByYear($event)" />
              <div class="sort-menu">
                <UdefSorting :sorting-options="sortingOptions" @change="changeSorting($event)" />
                <div class="arrows" @click="sortDirection = !sortDirection">
                  <v-icon class="arrow-up">
                    mdi-chevron-up
                  </v-icon>

                  <v-icon class="arrow-down">
                    mdi-chevron-down
                  </v-icon>
                </div>
              </div>
            </div>
          </div>
        </section>

        <UdefLoader v-if="loading.leaders" />
        <section v-else :class="{ grid: isGridView }" class="leaders">
          <template v-if="!isGridView">
            <div class="leaderboard-header">
              <div class="content">
                <div class="rank">
                  Rank
                </div>
                <div class="username">
                  Username
                </div>
                <div class="country">
                  Country
                </div>
                <div class="points">
                  Points earned
                </div>
              </div>
            </div>
            <UdefUserCardHorizontal
              v-for="(leader) in leaders"
              :key="leader.id"
              :user="leader"
              class="leader"
            />

            <InfiniteLoading :identifier="infiniteId" @infinite="loadLeaders">
              <div slot="spinner">
                <UdefLoader />
              </div>
              <template #no-more />
              <template #no-result />
            </InfiniteLoading>
          </template>

          <template v-if="isGridView" class="d-flex flex-wrap">
            <UdefUserCardVertical
              v-for="(leader) in leaders"
              :key="leader.id"
              :user="leader"
              class="leader-grid"
              is-show-rank
            />

            <InfiniteLoading :identifier="infiniteId" @infinite="loadLeaders">
              <div slot="spinner">
                <UdefLoader />
              </div>
              <template #no-more />
              <template #no-result />
            </InfiniteLoading>
          </template>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import api from '@/api'
import UdefUserCardHorizontal from '@/components/UdefUserCardHorizontal'
import UdefUserCardVertical from '@/components/UdefUserCardVertical'
import InfiniteLoading from 'vue-infinite-loading'
import FilterByYear from '@/components/FilterByYear.vue'

export default {
  name: 'Leaderboard',
  components: {
    FilterByYear,
    UdefUserCardHorizontal,
    UdefUserCardVertical,
    InfiniteLoading
  },
  data() {
    return {
      infiniteId: +new Date(),
      loading: {
        leaders: false
      },
      isGridView: false,
      leaders: [],
      leadersSearchTimeout: null,
      sortDirection: true,
      countUsers: 0,
      sortingOptions: [
        { name: 'rank', label: 'Rank' },
        { name: 'name', label: 'A-Z' }
      ],
      searchParams: {
        pageNumber: 0,
        pageSize: 8,
        keyword: '',
        sortBy: 'rank',
        sortDir: 'asc',
        year: new Date().getFullYear()
      }
    }
  },
  computed: {
    searchKeyword() {
      return this.searchParams.keyword
    }
  },
  watch: {
    searchKeyword() {
      clearTimeout(this.leadersSearchTimeout)
      this.leadersSearchTimeout = setTimeout(async() => {
        await this.reloadLeaders()
      }, 600)
    },
    async sortDirection(value) {
      this.searchParams.sortDir = value ? 'asc' : 'desc'
      await this.reloadLeaders()
    }
  },
  async mounted() {
    this.countUsers = await api.users.getUsersCount()
  },
  methods: {
    async loadLeaders($state) {
      const leaders = await api.users.getLeaderboard(this.searchParams)
      if (leaders.length) {
        this.leaders = [...this.leaders, ...leaders]
      }

      if (leaders.length === this.searchParams.pageSize) {
        this.searchParams.pageNumber += 1
        $state.loaded()
        return
      }

      $state.complete()
    },
    async changeSorting(field) {
      this.searchParams.sortBy = field
      await this.reloadLeaders()
    },
    async filterByYear(year) {
      this.searchParams.year = year
      await this.reloadLeaders()
    },
    async reloadLeaders() {
      window.scrollTo({ top: 0 })
      this.loading.leaders = true
      this.searchParams.pageNumber = 0
      this.leaders = []
      this.infiniteId += 1
      this.loading.leaders = false

      // Solves the infinite loader problem with v-if
      if (!this.isGridView) return
      const leaders = await api.users.getLeaderboard(this.searchParams)
      if (leaders && leaders.length) {
        this.leaders = [...this.leaders, ...leaders]
        this.searchParams.pageNumber += 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.hero-title {
  min-height: 650px;
  font-size: $font-hero-title;
  font-weight: $font-weight-bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;

  @media screen and (max-width: $screen-md) {
    min-height: 268px;
    font-size: $font-section-title-mobile;
  }
}

.sort-section {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 40px 55px;

  @media screen and (max-width: $screen-md) {
    flex-direction: column;
    padding: 40px 20px;
  }
}

.space {
  max-width: 200px;
  width: 100%;
}

.search-container {
  max-width: 420px;
  width: 100%;
  margin: auto;
}

.options {
  display: flex;
  flex: 0 0 fit-content;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  @media screen and (max-width: $screen-md) {
    max-width: 100%;
    justify-content: space-between;
    margin-top: 20px;
  }

  .view {
    .icon {
      margin-right: 20px;
      color: $black-light;

      @media screen and (max-width: $screen-md) {
        font-size: $font-icon-big;
      }

      &.active {
        cursor: default;
        opacity: 0.2;
      }
    }
  }

  .sort-filter-wrap {
    display: flex;
    gap: 20px;

    .sort-menu {
      display: flex;
      gap: 10px;
    }

    @media screen and (max-width: $screen-sm) {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }

  .arrows {
    width: 20px;
    margin-left: 10px;
    position: relative;
    cursor: pointer;

    button {
      height: 12px;
    }

    button:focus::after {
      opacity: 0;
    }

    i {
      color: $black-light
    }

    .arrow-up,
    .arrow-down {
      position: absolute;
    }

    .arrow-up {
      top: -6px;
    }

    .arrow-down {
      bottom: -6px;
    }
  }
}

.leaders {

  &.grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    padding: 0 50px;
    justify-content: center;

    @media screen and (max-width: $screen-md) {
      padding: 0 20px;
    }
  }
}

.leaderboard-header {

  @media screen and (max-width: $screen-md) {
    display: none;
  }

  .content {
    max-width: 810px;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    padding: 22px 0;
    color: $dark;
    font-size: $font-small;
  }

  .rank {
    max-width: 78px;
    width: 100%;
  }

  .username {
    max-width: 350px;
    width: 100%;
  }

  .country {
    max-width: 275px;
    width: 100%;
  }
}

.leader {
  margin-bottom: 8px;
}

.leader-grid {
  margin: 0 20px 20px;
}
</style>
