<template>
  <article :class="{ 'large': large }" class="event">
    <div class="img-wrapper">
      <div class="links">
        <UdefButton :to="{ name: 'Event', params: { id: event.id} }" width="216" height="52">
          Event info
        </UdefButton>
        <div class="share">
          Share the event
          <div class="socials">
            <a target="_blank" :href="facebookShareUrl">
              <img src="/img/socials/facebook-white.svg" alt="facebook">
            </a>
            <a target="_blank" :href="twitterShareUrl">
              <img src="/img/socials/twitter-white.svg" alt="twitter">
            </a>
          </div>
        </div>
      </div>
      <img alt="event image" class="img" :src="getEventImg(event.image, 840)">
    </div>
    <div class="description">
      <div class="title">
        {{ event.title }}
      </div>
      <div class="date">
        {{ eventDate }}
      </div>
    </div>
  </article>
</template>

<script>
import Images from '@/mixins/image'
import Dates from '@/mixins/date'
import { getTimeRangeFormatted, getDateRangeFormatted } from '@/services/date'

export default {
  name: 'UdefEventCardVertical',
  mixins: [Images, Dates],
  props: {
    event: {
      type: Object,
      required: true
    },
    large: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getTimeRangeFormatted,
    getDateRangeFormatted
  },
  computed: {
    eventDate() {
      return new Date(this.event.dateStart).toLocaleDateString('en-US')
    },
    eventUrl() {
      const eventUrl = `${window.location.origin}/event/${this.event.id}`
      return eventUrl
    },
    twitterShareUrl() {
      const twitterUrl = 'https://twitter.com/intent/tweet'
      const shareUrl = `${twitterUrl}?text=${
        this.event.title
      } - ${this.getDateRangeFormatted(new Date(this.event.dateStart), new Date(this.event.dateEnd))} -
      ${this.getDateRangeFormatted(new Date(this.event.dateStart), new Date(this.event.dateEnd))} - ${
        this.event.locationAddress
      }&url=${this.eventUrl}`
      return shareUrl
    },
    facebookShareUrl() {
      const facebookUrl = 'https://www.facebook.com/sharer/sharer.php'
      const shareUrl = `${facebookUrl}?u=${encodeURI(this.eventUrl)}`
      return shareUrl
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.event {
  max-width: 305px;
  width: 100%;

  @media screen and (max-width: $screen-md) {
    max-width: 335px;
  }

  &.large {
    max-width: 420px;

    @media screen and (max-width: $screen-md) {
      max-width: 335px;
    }

    .title{
      font-size: $font-bigger;
    }

    .date {
      font-size: $font-medium;
    }

    .img-wrapper {
      height: 420px;

      @media screen and (max-width: $screen-md) {
        height: 335px;
      }
    }

    .links {
      padding: 184px 102px 70px;

      @media screen and (max-width: $screen-md) {
        padding: 126px 59px 70px;
      }
    }

    .share {
      margin-top: 66px;
    }
  }
}

.img-wrapper {
  position: relative;
  height: 305px;

  @media screen and (max-width: $screen-md) {
    height: 335px;
  }

  &::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background-color: $black-light;
    opacity: 0;
    transition-duration: 0.3s;
  }

  &:hover::before {
    opacity: 0.9;
  }

  &:hover .links {
    display: block;
  }
}

.links {
  display: none;
  padding: 126px 44px 40px;
  position: absolute;
  height: 100%;
  width: 100%;

  @media screen and (max-width: $screen-md) {
    padding: 126px 59px 40px;
  }
}

.share {
  margin-top: 38px;
  color: $white;
  text-align: center;
}

.socials {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 24px;
    height: 24px;
    margin: 10px;
  }
}

.img {
  width: 100%;
  cursor: pointer;
}

.description {
  text-align: center;

  .title{
    font-weight: $font-weight-bold;
    font-size: $font-big;
  }

  .date {
    font-size: $font-small;
    font-weight: $font-weight-bold;
  }
}

</style>
