<template>
  <section>
    <UdefLoader v-if="loading" />
    <div v-else>
      <UdefHero
        :dark-filter="!!featureArcticle"
        :img="featureArcticle ? featureArcticle.image : '/img/hero-index.webp'"
      >
        <div v-if="!featureArcticle" class="hero-img">
          <img src:="/img/logo-noised.svg" alt="UDEF">
        </div>

        <div v-else class="hero-article">
          <h1>{{ featureArcticle.title }}</h1>
          <h2>{{ featureArcticle.text }}</h2>
          <UdefButton
            :to="{ name: 'Article', params: { id: featureArcticle.id } }"
            class="read-btn"
            :width="$vuetify.breakpoint.mdAndDown ? '100%' : '190'"
            height="52"
          >
            Read article
          </UdefButton>
        </div>
      </UdefHero>

      <IndexHappening :articles="articles" />
      <IndexSignUpNewsletter />
      <IndexWinners :winners="winners" />
      <IndexEvents :events="events" />
    </div>
  </section>
</template>

<script>
import api from '@/api'
import Truncate from '@/mixins/truncate'
import Dates from '@/mixins/date'
import Images from '@/mixins/image'

import IndexHappening from '@/components/IndexHappening'
import IndexSignUpNewsletter from '@/components/IndexSignUpNewsletter'
import IndexWinners from '@/components/IndexWinners'
import IndexEvents from '@/components/IndexEvents'
import { DEFAULT_IMAGES } from '@/common/constants'

export default {
  name: 'Index',
  components: {
    IndexHappening,
    IndexSignUpNewsletter,
    IndexWinners,
    IndexEvents
  },
  mixins: [Truncate, Dates, Images],
  data() {
    return {
      loading: true,
      articles: [],
      winners: [],
      events: [],
      featureArcticle: null
    }
  },
  async mounted() {
    this.loading = true
    const resp = await api.articles.getArticles(5)
    if (resp) {
      this.articles = resp.items
      const [firstArticle] = this.articles
      this.articles.shift()

      const htmlText = document.createElement('span')
      htmlText.innerHTML = firstArticle.content
      const featuredArticleText = htmlText.textContent

      const firstImage = htmlText.querySelector('img')
      const featuredArticleImage = firstImage
        ? firstImage.src
        : DEFAULT_IMAGES.article

      this.featureArcticle = {
        id: firstArticle.id,
        title: this.getStringTruncated(firstArticle.title, 60),
        date: this.getDate(firstArticle.published),
        text: this.getStringTruncated(featuredArticleText, 185),
        image: featuredArticleImage,
        author: {
          name: firstArticle.author.displayName,
          avatar: firstArticle.author.image.url
        }
      }
    }

    this.winners = await api.users.getWinners()

    const eventsSearch = {
      pageNumber: 0,
      pageSize: 3,
      timeFilter: 'future',
      sortBy: 'date',
      sortDir: 'desc'
    }
    this.events = await api.events.getEvents(eventsSearch)

    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/global.scss";

.hero-img {
  min-height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $screen-md) {
    min-height: 268px;
  }

  img {
    width: 100%;
  }
}

.hero-article {
  padding: 318px 540px 50px 80px;
  color: $white;

  @media screen and (max-width: $screen-md) {
    min-height: unset;
    padding: 192px 20px 20px;
  }

  h1 {
    font-size: $font-section-title;
    line-height: 64px;

    @media screen and (max-width: $screen-sm) {
      font-size: $font-section-title-mobile;
      line-height: 32px;
    }
  }

  h2 {
    display: block;
    min-height: 72px;
    font-size: $font-medium;
    font-weight: $font-weight-normal;

    @media screen and (max-width: $screen-sm) {
      margin-top: 8px;
      min-height: 48px;
    }
  }

  .read-btn {
    margin-top: 20px;
  }
}
</style>
