<template>
  <section class="terms">
    <h1 class="terms-title">
      Terms & conditions
    </h1>

    <p>Last Updated: December 12, 2018</p>

    <h3 class="paragraph-title">
      Introduction
    </h3>

    <p>
      The Urban Dance & Educational Foundation (referred to herein as “UDEF”) is
      committed to providing the users of this website (“Website”) with
      information on the physical expression, intellectual understanding and
      educational and teaching programs of the urban arts, including breaking
      and other forms of street dance, through dance participation,
      performances, camps, seminars, work-shops, competitive events, tours and
      series.
    </p>

    <p>
      UDEF values the privacy of its members and guests. UDEF provides notice
      explaining its policies for data collected through this site, the Internet
      and other means available here.
    </p>

    <p>
      By using this site, you agree with this Terms and Conditions of Service.
    </p>

    <p>
      <span class="paragraph-title">Copyright</span> . All material on this
      Website, and the files associated with it, are copyrighted by UDEF or by
      the original creator of that material. It is protected by the copyright
      laws of the United States of America and under treaties with other
      nations. Unless otherwise stated, no material on this Website is in the
      public domain. You may download, copy, reproduce, redistribute, republish,
      display, post, or transmit material on this Website only if expressly
      permitted in writing, and then only the designated material. UDEF in no
      way represents or warrants that your use of materials displayed on this
      Website does not infringe the rights of third parties.
    </p>

    <p>
      <span class="paragraph-title">Trademarks</span>. All trademarks, service
      marks, and logos (“Trademarks”) displayed and used in this Website are the
      property of their respective owners. Nothing in this Website should be
      construed as granting any right or license to use any Trademark without
      the written permission of its owner.
    </p>

    <p>
      <span class="paragraph-title">Accuracy</span>. While making reasonable
      efforts to ensure that all information in this Website is accurate and up
      to date, UDEF makes no representation or warranty of the accuracy,
      reliability, or completeness of the information.
    </p>

    <p>
      <span class="paragraph-title">Disclaimer</span>. All information that UDEF
      provides on its site(s) or by any other means is for informational and
      directional purposes only; it does not address specific circumstances of
      any particular entity; it is not necessarily complete, accurate or up to
      date; it is not professional or legal advice, or a representation or
      warranty related to such information. Hence, UDEF disclaims any
      responsibility for any harm or loss, however arising, from the use of or
      reliance on this information. Your use of this site is subject to this
      Terms of Service and the Privacy Policy. If you choose to use this site,
      your visit and anything that occurs through your use of this site, shall
      be your sole responsibility, apart from any gross negligence on our part.
      Any dispute over privacy and data collection is subject to this Terms of
      Service and the Privacy Policy. This agreement, and all claims related to
      the use of the site shall be governed by the laws of the State of
      Pennsylvania.
    </p>

    <p>
      TO THE EXTENT PERMITTED BY LAW, IN NO EVENT SHALL UDEF OR ITS AFFILIATES
      BE LIABLE FOR PERSONAL INJURY, DEATH, OR ANY DIRECT, INDIRECT, INCIDENTAL,
      SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES WHATSOEVER, INCLUDING, WITHOUT
      LIMITATION, DAMAGES FOR ANY LOSS ATTRIBUTABLE TO MISUSE OF DATA OR ANY
      OTHER DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR
      INABILITY TO USE THE SITE.
    </p>

    <p>
      <span class="paragraph-title">Communications by you</span>. Any
      communication or material you transmit to the Website by electronic mail
      or otherwise, including data, questions, comments, suggestions, or the
      like, is, and may be treated as, non-confidential and non-proprietary. By
      transmitting or posting communications or materials to the Website, you
      grant UDEF a perpetual, royalty free license to use the communications or
      materials for any purpose, including, but not limited to, reproduction,
      disclosure, transmission, publication, broadcast, and posting.
      Furthermore, UDEF is free to use any ideas, concepts, know-how, or
      techniques in any communication that you send to the Website, for any
      purpose whatsoever, including, but not limited to, developing,
      manufacturing, and marketing products.
    </p>

    <p>
      <span class="paragraph-title">Changes</span> to the Terms of Service. If
      this Terms of Service change, the revised policy will be posted on this
      site. Please check back periodically, and especially before you provide
      any personally identifiable information.
    </p>
  </section>
</template>

<script>
export default {
  name: 'Terms'
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/global.scss";

.terms {
  max-width: 880px;
  margin: auto;
  padding-top: 80px;

  @media screen and (max-width: $screen-md) {
    padding: 40px 20px 0 20px;
  }

  p,
  .paragraph-title {
    margin-bottom: 20px;
  }

  .paragraph-title {
    font-weight: $font-weight-bold;
  }

  .terms-title {
    font-size: $font-section-title;
    font-weight: $font-weight-bold;
    margin-bottom: 20px;

    @media screen and (max-width: $screen-md) {
      font-size: $font-section-title-mobile;
    }
  }
}
</style>
