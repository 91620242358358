
import { DEFAULT_IMAGES } from '../common/constants'

import flags from '../../public/flags'
const BASE_URL = process.env.VUE_APP_IMG_URL

function getImageById(id, width, height, type = 'avatar') {
  if (!id) { return DEFAULT_IMAGES[type] }
  return `${BASE_URL}/${id}/${width}/${height}`
}

const Images = {
  methods: {
    getAvatar(id, width, height = width) {
      return getImageById(id, width, height, 'avatar')
    },
    getEventImg(id, width, height = width) {
      return getImageById(id, width, height, 'default')
    },
    getFlag(countryName) {
      if (!countryName) { return '/img/flag-earth.png' }
      const countryFlag = flags.find(flag => flag.name === countryName)
      return countryFlag.flag
    }
  }
}

export default Images
