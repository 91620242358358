<template>
  <div class="sort">
    <div class="mr-1">
      Sort by:
    </div>

    <v-menu class="sort-menu" nudge-width="242" offset-y>
      <template #activator="{ on }">
        <div class="current" v-on="on">
          {{ currentSortingOption.label }}
        </div>
      </template>
      <v-list class="sort-container">
        <v-list-item>
          <v-list-item-title class="sort-title">
            Sort by
          </v-list-item-title>
        </v-list-item>
        <v-radio-group v-model="sortingRadio" class="mt-0">
          <v-list-item
            v-for="(sortingOption, index) in sortingOptions"
            :key="index"
          >
            <v-list-item-title
              class="sort-option"
              @click="changeSorting(sortingOption, index)"
            >
              {{ sortingOption.label }} <v-radio
                color="#041E42"
                :value="index"
              />
            </v-list-item-title>
          </v-list-item>
        </v-radio-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'UdefSorting',
  props: {
    sortingOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      sortingRadio: 0,
      currentSortingOption: this.sortingOptions[0]
    }
  },
  methods: {
    changeSorting(sortingOption, index) {
      this.currentSortingOption = sortingOption
      this.sortingRadio = index
      this.$emit('change', this.currentSortingOption.name)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/style/global.scss';

.sort-container .v-list-item__title.sort-option {
  cursor: pointer;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sort-container .v-list-item__title.sort-title {
  font-weight: $font-weight-bold;
}
</style>

<style lang="scss" scoped>

.sort {
display: flex;

  .current {
    cursor: pointer;
  }

  .sort-option {
    padding: 16px 0;
    display: flex;
  }
}
</style>
