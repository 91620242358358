import { publicBKApi } from '@/plugins/axios'

export const getMedia = async(keyword) => {
  try {
    const params = { k: keyword, o: process.env.VUE_APP_ORG_ID }
    const { data } = await publicBKApi.get('/media', { params })
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getEventsMedia = async(keyword) => {
  try {
    const params = { f: 'events', k: keyword }
    const { data } = await publicBKApi.get(`orgs/${process.env.VUE_APP_ORG_ID}/media`, { params })
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}
