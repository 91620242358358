<template>
  <div class="background">
    <section class="newsletter udef-content">
      <h2 class="newsletter-title">
        Sign up for our newsletter
      </h2>
      <p class="subtitle">
        Keep up with the latest news, events, and more!
      </p>
      <div v-if="isSubscribed" class="successful-subscribe">
        Thank you for sign up!
      </div>
      <div v-else class="sign-form">
        <UdefInput :error="!!emailError" placeholder="Enter your email" :value.sync="userEmail" />

        <div class="sign-btn">
          <span v-if="emailError" class="validation-error">{{ emailError }}</span>
          <UdefButton
            :loading="loading"
            :width="$vuetify.breakpoint.mdAndDown ? '100%' : '190'"
            height="52"
            color="primary"
            @click="signUpForNewsletter"
          >
            Submit
          </UdefButton>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from '@/api'

import { email } from 'vuelidate/lib/validators'

export default {
  name: 'IndexSignUpNewsletter',
  data() {
    return {
      userEmail: '',
      emailError: '',
      isSubscribed: false,
      loading: false
    }
  },
  validations() {
    return {
      userEmail: {
        maxLength: email
      }
    }
  },
  watch: {
    userEmail() {
      this.emailError = ''
    }
  },
  methods: {
    async signUpForNewsletter() {
      if (this.$v.userEmail.$invalid || !this.userEmail) {
        this.emailError = 'Invalid Email address'
        return
      }
      this.loading = true
      const resp = await api.users.subscibeNewsletter(this.userEmail)
      if (!resp) {
        this.emailError = 'An error occured or incorrect email'
        this.loading = false
        return
      }

      this.isSubscribed = true
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';
.background {
  background: center/ cover no-repeat url(/img/newsletter-background.webp);
}

.newsletter {
  color: $black-light;
  padding: 80px 360px 60px;

  @media screen and (max-width: $screen-md) {
   padding: 80px 150px;
  }

  @media screen and (max-width: $screen-sm) {
   padding: 60px 20px;
  }
}

.newsletter,
.title {
 text-align: center;

  @media screen and (max-width: $screen-md) {
   text-align: inherit;
  }
}

.newsletter-title {
  font-size: $font-section-title;
  font-weight: $font-weight-bold;

  @media screen and (max-width: $screen-md) {
   font-size: $font-section-title-mobile;
  }
}

.subtitle {
  color: $dark
}

.successful-subscribe {
  padding: 45px 0;
  font-size: $font-big;
  font-weight: $font-weight-bold;
}

.sign-form {
  padding: 0 20px;

  @media screen and (max-width: $screen-md) {
   padding: 0;
  }

  .validation-error {
    color: $red;
    margin-right: 20px;
  }

  .sign-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 12px;
  }
}

</style>
