<template>
  <section class="legal">
    <h1 class="legal-title">
      Legal & tax information
    </h1>

    <div class="paragraph">
      <h3 class="paragraph-title">
        Certificate of incorporation
      </h3>
      <a target="_blank" href="/docs/UDEF-Certificate-of-Incorporation.pdf">
        UDEF Certificate of incorporation
      </a>
    </div>

    <div class="paragraph">
      <h3 class="paragraph-title">
        Form 990 filings
      </h3>
      <a target="_blank" href="/docs/UDEF-2022-990-PDC.pdf">
        UDEF Form 990 – 2022
      </a>
      <a target="_blank" href="/docs/UDEF-2021-990-PDC.pdf">
        UDEF Form 990 – 2021
      </a>
      <a target="_blank" href="/docs/UDEF_2020_990_PDC.pdf">
        UDEF Form 990 – 2020
      </a>
      <a target="_blank" href="/docs/UDEF_2019_990_PDC_signed_2.pdf">
        UDEF Form 990 – 2019
      </a>
      <a target="_blank" href="/docs/UDEF-2018-990-PDC.pdf">
        UDEF Form 990 – 2018
      </a>
      <a target="_blank" href="/docs/UDEF-2017-990.pdf">
        UDEF Form 990 – 2017
      </a>
      <a target="_blank" href="/docs/UDEF-2016-990.pdf">
        UDEF Form 990 – 2016
      </a>
      <a target="_blank" href="/docs/UDEF-2015-990.pdf">
        UDEF Form 990 – 2015
      </a>
      <a target="_blank" href="/docs/UDEF-2014-990.pdf">
        UDEF Form 990 – 2014
      </a>
    </div>

    <div class="paragraph">
      <h3 class="paragraph-title">
        Non-profit status
      </h3>
      <a target="_blank" href="/docs/UDEF-IRS-Tax-Exempt-Application.pdf">
        UDEF IRS Tax Exempt Application
      </a>
      <a target="_blank" href="/docs/UDEF-IRS-Determination-Letter.pdf">
        UDEF IRS Determination Letter
      </a>
    </div>

    <div class="paragraph">
      <h3 class="paragraph-title">
        Organizational structure
      </h3>
      <a
        target="_blank"
        href="/docs/UDEF-Organizational-Structure-as-of-12.31.2018.pdf"
      >
        UDEF Organizational Structure Chart
      </a>
      <a
        target="_blank"
        href="/docs/UDEF-Board-of-Representatives-as-of-June.7.2021.pdf"
      >
        UDEF Board of Representatives
      </a>
    </div>

    <div class="paragraph">
      <h3 class="paragraph-title">
        Other
      </h3>
      <p>
        Other organizational information available on request
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Legal'
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/global.scss";

.legal {
  max-width: 880px;
  margin: auto;
  padding-top: 80px;

  @media screen and (max-width: $screen-md) {
    padding: 40px 20px 0 20px;
  }

  a {
    display: block;
  }

  .paragraph-title {
    font-weight: $font-weight-bold;
  }

  .paragraph {
    margin-bottom: 20px;
  }

  .legal-title {
    font-size: $font-section-title;
    font-weight: $font-weight-bold;
    margin-bottom: 20px;

    @media screen and (max-width: $screen-md) {
      font-size: $font-section-title-mobile;
    }
  }
}
</style>
