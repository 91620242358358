import { externalApi } from '@/plugins/axios'

const BLOGGER_API_URL = 'https://www.googleapis.com/blogger/v3'

export const getArticles = async(limit) => {
  try {
    const params = {
      key: process.env.VUE_APP_BLOGGER_API_KEY,
      maxResults: limit
    }
    const { data } = await externalApi.get(`${BLOGGER_API_URL}/blogs/${process.env.VUE_APP_BLOGGER_BLOG_ID}/posts`, { params })
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) { return null }
  }
}

export const searchArticles = async(searchKeyword) => {
  try {
    const params = {
      key: process.env.VUE_APP_BLOGGER_API_KEY,
      q: searchKeyword
    }
    const { data } = await externalApi.get(`${BLOGGER_API_URL}/blogs/${process.env.VUE_APP_BLOGGER_BLOG_ID}/posts/search`, { params })
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) { return null }
  }
}

export const getArticle = async(articleId) => {
  try {
    const params = {
      key: process.env.VUE_APP_BLOGGER_API_KEY,
      fetchImages: true
    }
    const { data } = await externalApi.get(`${BLOGGER_API_URL}/blogs/${process.env.VUE_APP_BLOGGER_BLOG_ID}/posts/${articleId}`, { params })
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) { return null }
  }
}
