import Vue from 'vue'
import UdefButton from '@/components/UdefButton'
import UdefHero from '@/components/UdefHero'
import UdefInput from '@/components/UdefInput'
import UdefIcon from '@/components/UdefIcon'
import UdefLoader from '@/components/UdefLoader'
import UdefSorting from '@/components/UdefSorting'

Vue.component('UdefButton', UdefButton)
Vue.component('UdefHero', UdefHero)
Vue.component('UdefInput', UdefInput)
Vue.component('UdefIcon', UdefIcon)
Vue.component('UdefLoader', UdefLoader)
Vue.component('UdefSorting', UdefSorting)
