const Truncate = {
  methods: {
    getStringTruncated(string, size) {
      return string?.length > size 
        ? `${string.substr(0, size - 1)}...` 
        : string
    }
  }
}
export default Truncate
