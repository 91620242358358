import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/udef'
import './plugins/vuelidate'
import VueSanitize from 'vue-sanitize'
import vueSanitizeConfig from './common/vueSanitizeConfig'

Vue.config.productionTip = false

Vue.use(VueSanitize, vueSanitizeConfig)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
