<template>
  <div class="story udef-content">
    <div class="story-item">
      <img class="image" src="/img/story-1.webp" alt="UDEF Story">
      <div class="wrapper">
        <v-spacer />
        <div class="content">
          <div class="story-title">
            UDEF Story
          </div>
          <div class="text">
            UDEF is an educational foundation
            with the primary purpose of helping
            street dancers establish business opportunities
            and jobs through the athletic dance of breaking,
            which was created in the streets of South Bronx,
            New York in the 1970s and has recently been incorporated
            into the Olympic movement by the International Olympic Committee.
          </div>

          <div class="text">
            UDEF has sanctioned several
            hundred competitive breaking events since
            its formation in late 2013, and today UDEF’s
            registered competitor membership base includes
            thousands of competitors
            from over 100 nations.
          </div>

          <div class="text subtext">
            The Urban Dance & Educational Foundation (UDEF)
            is a not-for-profit corporation and public charity
            under Section 501(c)3 of the United States Internal
            Revenue Code.
          </div>
        </div>
      </div>
    </div>
    <div class="story-item">
      <img class="image right" src="/img/story-2.webp" alt="What we do">
      <div class="wrapper">
        <div class="content shadow-reversed">
          <div class="story-title">
            What we do
          </div>
          <div class="text">
            UDEF supports charitable events
            and educational workshops in community
            centers, schools, colleges, and similar venues,
            with breakers performing, teaching and working
            with young people.
          </div>

          <div class="text">
            UDEF also serves as a membership organization
            for competitors in breaking and as a sanctioning
            body for breaking events, setting competition standards,
            judging criteria, and other guidelines to ensure uniform
            standards for competitive events.
          </div>
        </div>
      </div>
    </div>
    <div class="story-item">
      <img class="image" src="/img/story-3.webp" alt="UDEF the non profit">
      <div class="wrapper">
        <v-spacer />
        <div class="content">
          <div class="story-title">
            UDEF the non profit
          </div>
          <div class="text">
            UDEF is an educational foundation with the
            primary purpose of helping street dancers establish
            business opportunities and jobs through the athletic
            dance of breaking, which was created in the streets of
            South Bronx, New York in the 1970s and has recently been
            incorporated into the Olympic movement by the International
            Olympic Committee.
          </div>

          <div class="text">
            UDEF has sanctioned several hundred competitive breaking
            events since its formation in late 2013, and today UDEF’s
            registered competitor membership base includes thousands of
            competitors
            from over 100 nations.
          </div>

          <UdefButton
            href="https://www.paypal.com/donate/?hosted_button_id=F6RT4N3ZJQK7S"
            :width="$vuetify.breakpoint.mdAndDown ? '100%' : '190'"
            height="52"
            class="donate-btn"
            color="primary"
          >
            Donate
          </UdefButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutStory'
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.story {
  color: $black-light;
  background-image: url(/img/zoom-logo-red.svg);
}

.story-item {
  padding: 40px 165px 40px 165px;
  position: relative;

  @media screen and (max-width: $screen-md) {
   padding: 40px 20px;
  }

  .image {
    position: absolute;
    height: 575px;
    width: 575px;

    @media screen and (max-width: $screen-sm) {
      position: unset;
      width: 100%;
      height: 100%;
    }

    &.right {
      right: 165px;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    height: 575px;

    @media screen and (max-width: $screen-sm) {
      display: block;
      height: 100%;
    }
  }

  .content {
    position: relative;
    z-index: 1;
    width: 57%;
    background-color: $white;
    padding: 20px;
    box-shadow: $container-shadow;

    &.shadow-reversed {
      box-shadow: $container-shadow-reversed;

      @media screen and (max-width: $screen-sm) {
        box-shadow: unset;
      }
    }

    @media screen and (max-width: $screen-sm) {
      width: 100%;
      box-shadow: unset;
      padding: 0;
    }

    .text {
      padding-top: 20px;
    }

    .subtext {
      color: $grey;
      font-size: $font-small
    }
  }

  .story-title {
    font-size: $font-section-title;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $screen-md) {
      font-size: $font-section-title-mobile;
    }
  }

  .donate-btn {
    margin-top: 30px;
  }
}
</style>
