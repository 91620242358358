<template>
  <div>
    <section v-if="searchParams.timeFilter">
      <UdefHero
        v-if="isUpcomingEventLoaded"
        :blur="!!upcomingEvent"
        :img="upcomingEvent ? getEventImg(upcomingEvent.image, 200) : defaultImages.article"
      >
        <div class="hero-event">
          <v-img
            v-if="upcomingEvent"
            class="img"
            :src="upcomingEvent ? getEventImg(upcomingEvent.image, 600) : defaultImages.article"
          />
          <div v-if="upcomingEvent" class="wrapper">
            <h1>{{ upcomingEvent.title }}</h1>
            <h2 class="description-text" v-html="getStringTruncated($sanitize(upcomingEvent.description), 140)" />
            <div class="share">
              Share the event
            </div>

            <div class="socials">
              <a target="_blank" :href="facebookShareUrl">
                <img class="icon" src="/img/socials/facebook-white.svg" alt="facebook">
              </a>
              <a target="_blank" :href="twitterShareUrl">
                <img class="icon" src="/img/socials/twitter-white.svg" alt="twitter">
              </a>
            </div>

            <UdefButton
              :to="{name: 'Event', params: { id: upcomingEvent.id} }"
              :width="$vuetify.breakpoint.mdAndDown ? '100%' : '200'"
              height="52"
              color="white"
              outlined
              class="event-btn"
            >
              Event info
            </UdefButton>
          </div>
        </div>

        <section class="period-switch udef-content">
          <div class="wrapper">
            <div
              v-if="availableTabs.now"
              :class="{ active: period ==='now' }"
              class="option"
              @click="searchParams.timeFilter = 'now'"
            >
              Happening now
            </div>

            <div
              v-if="availableTabs.future"
              :class="{ active: period ==='future' }"
              class="option"
              @click="searchParams.timeFilter = 'future'"
            >
              Upcoming
            </div>

            <div
              v-if="availableTabs.past"
              :class="{ active: period ==='past' }"
              class="option"
              @click="searchParams.timeFilter = 'past'"
            >
              Past
            </div>
          </div>
        </section>
      </UdefHero>
      <div class="udef-content">
        <section v-if="isShowSearchBar" class="sort-section">
          <div class="search-container">
            <UdefInput :value.sync="searchParams.keyword" placeholder="Search events" />
          </div>
          <div class="options">
            <div class="view">
              <v-icon
                :class="{ active: !isGridView }"
                class="icon"
                @click="isGridView = false"
              >
                mdi-format-list-bulleted
              </v-icon>
              <v-icon
                :class="{ active: isGridView }"
                class="icon"
                @click="isGridView = true"
              >
                mdi-view-grid
              </v-icon>
            </div>
            <div class="sort">
              <UdefSorting :sorting-options="sortingOptions" @change="changeSorting($event)" />
              <div class="arrows" @click="sortDirection = !sortDirection">
                <v-icon class="arrow-up">
                  mdi-chevron-up
                </v-icon>

                <v-icon class="arrow-down">
                  mdi-chevron-down
                </v-icon>
              </div>
            </div>
          </div>
        </section>

        <UdefLoader v-if="loading.events" />
        <section v-else :class="{grid: isGridView}" class="events">
          <div v-for="(event,index) in events" :key="index">
            <template v-if="!isGridView">
              <UdefEventCardHorizontal
                :event="event"
                class="event"
              />
            </template>
            <div v-if="isGridView">
              <UdefEventCardVertical
                v-if="isGridView"
                :event="event"
                class="event-grid"
              />
            </div>
          </div>
          <InfiniteLoading :identifier="infiniteId" @infinite="loadEvents">
            <div slot="spinner">
              <UdefLoader />
            </div>
            <div slot="no-more" />
            <div slot="no-results" />
          </InfiniteLoading>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import api from '@/api'

import { DEFAULT_IMAGES } from '@/common/constants'

import Images from '@/mixins/image'
import Truncate from '@/mixins/truncate'
import Dates from '@/mixins/date'

import UdefEventCardHorizontal from '@/components/UdefEventCardHorizontal'
import UdefEventCardVertical from '@/components/UdefEventCardVertical'
import InfiniteLoading from 'vue-infinite-loading'
import { getTimeRangeFormatted, getDateRangeFormatted } from '@/services/date'

export default {
  name: 'Events',
  components: {
    UdefEventCardHorizontal,
    UdefEventCardVertical,
    InfiniteLoading
  },
  mixins: [Images, Truncate, Dates],
  data() {
    return {
      infiniteId: +new Date(),
      isUpcomingEventLoaded: false,
      loading: { events: false },
      eventsSearchTimeout: null,
      sortDirection: true,
      hasUpcomingEvents: false,
      searchParams: {
        pageNumber: 0,
        pageSize: 8,
        keyword: '',
        timeFilter: '',
        sortBy: 'date',
        sortDir: 'desc'
      },
      sortingOptions: [
        { name: 'date', label: 'Date' },
        { name: 'title', label: 'A-Z' }
      ],
      upcomingEvent: null,
      isGridView: false,
      events: [],
      defaultImages: DEFAULT_IMAGES,
      availableTabs: null
    }
  },
  computed: {
    period() {
      return this.searchParams.timeFilter
    },
    searchKeyword() {
      return this.searchParams.keyword
    },
    eventUrl() {
      const eventUrl = `${window.location.origin}/event/${this.upcomingEvent.id}`
      return eventUrl
    },
    twitterShareUrl() {
      const twitterUrl = 'https://twitter.com/intent/tweet'
      const shareUrl = `${twitterUrl}?text=${
        this.upcomingEvent.title
      } - ${this.getDateRangeFormatted(new Date(this.upcomingEvent.dateStart), new Date(this.upcomingEvent.dateEnd))} -
      ${this.getDateRangeFormatted(new Date(this.upcomingEvent.dateStart), new Date(this.upcomingEvent.dateEnd))} - ${
        this.upcomingEvent.locationAddress
      }&url=${this.eventUrl}`
      return shareUrl
    },
    facebookShareUrl() {
      const facebookUrl = 'https://www.facebook.com/sharer/sharer.php'
      const shareUrl = `${facebookUrl}?u=${encodeURI(this.eventUrl)}`
      return shareUrl
    },
    isShowSearchBar() {
      return this.searchParams.timeFilter === 'past' || this.hasUpcomingEvents
    }
  },
  watch: {
    async sortDirection(value) {
      this.searchParams.sortDir = value ? 'asc' : 'desc'
      await this.reloadEvents()
    },
    async period() {
      await this.reloadEvents()
    },
    searchKeyword() {
      clearTimeout(this.eventsSearchTimeout)
      this.eventsSearchTimeout = setTimeout(async() => {
        await this.reloadEvents()
      }, 600)
    }
  },
  async created() {
    await this.fetchAvailableTabs()

    const firstAvailableTab = Object.keys(this.availableTabs).find((tabName) => !!this.availableTabs[tabName])
    this.searchParams.timeFilter = firstAvailableTab || 'past'
  },
  methods: {
    getTimeRangeFormatted,
    getDateRangeFormatted,
    async loadEvents($state) {
      const events = await api.events.getEvents(this.searchParams)

      if (events.length) {
        this.events = [...this.events, ...events]
      }

      if (events.length === this.searchParams.pageSize) {
        this.searchParams.pageNumber += 1
        $state.loaded()
        return
      }

      if (this.events && !this.isUpcomingEventLoaded) {
        const [upcomingEvent] = this.events
        this.upcomingEvent = upcomingEvent || null
        this.isUpcomingEventLoaded = true

        if (this.events.length) this.hasUpcomingEvents = true
      }

      $state.complete()
    },
    async reloadEvents() {
      window.scrollTo({ top: 0 })
      this.loading.events = true
      this.searchParams.pageNumber = 0
      this.events = []
      this.infiniteId += 1
      this.loading.events = false

      // Solves the infinite loader problem with v-if
      if (!this.isGridView) return
      const events = await api.events.getEvents(this.searchParams)
      if (events && events.length) {
        this.events = [...this.events, ...events]
        this.searchParams.pageNumber += 1
      }
    },
    async changeSorting(field) {
      if (field === 'title') this.searchParams.sortDir = 'asc'
      this.searchParams.sortBy = field
      await this.reloadEvents()
    },
    async fetchAvailableTabs() {
      const data = await api.events.getEventsAvailableTabs()

      if (data) this.availableTabs = data
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.hero-event {
  padding: 60px 165px;
  display: flex;
  align-items: center;
  min-height: 665px;

  @media screen and (max-width: $screen-md) {
    flex-direction: column;
    padding: 40px 20px;
  }

  .img {
    width: 100%;
    max-width: 535px;
    display: block;
    height: 535px;
    margin-right: 40px;
    box-shadow: $horizontal-card-shadow;

    @media screen and (max-width: $screen-md) {
      width: 335px;
      height: 335px;
      margin: auto;
    }
  }

  .wrapper {
    z-index: 1;
    color: $white
  }

  h1 {
    line-height: 64px;
    font-size: $font-section-title;

    @media screen and (max-width: $screen-md) {
      margin-top: 20px;
      line-height: 40px;
      font-size: $font-section-title-mobile;
    }

  }

  h2 {
    font-weight: $font-weight-normal;
    margin-top: 26px;
    font-size: $font-medium;
  }

  .share,
  .socials  {
    margin-top: 20px;

    @media screen and (max-width: $screen-md) {
      text-align: center;
    }
  }

  .socials {
    .icon {
      margin-right: 20px;

      @media screen and (max-width: $screen-md) {
        width: 40px;
        height: 40px;
      }
    }
  }

  .event-btn {
    margin: 40px 0;
  }
}

.period-switch {
  position: absolute;
  width: 100%;

  .wrapper {
    display: flex;
    justify-content: center;
    max-width: 420px;
    margin: -30px auto 0 auto;
    background-color: $white;
    box-shadow: $horizontal-card-shadow;
  }

  .option {
    cursor: pointer;
    font-weight: $font-weight-bold;
    color: $black-light;
    padding: 14px 0;
    text-align: center;
    max-width: 210px;
    width: 100%;

    &.active {
      border-bottom: 4px solid $red;
    }
  }
}

.sort-section {
  display: flex;
  justify-content: space-between;
  padding: 40px 50px;

  @media screen and (max-width: $screen-md) {
    flex-direction: column;
    padding: 70px 20px;
  }
}

.space {
  max-width: 200px;
  width: 100%;
}

.search-container {
  max-width: 420px;
  width: 100%;
}

.options {
  display: flex;
  align-items: center;
  max-width: 220px;
  width: 100%;

  @media screen and (max-width: $screen-md) {
    max-width: 100%;
    justify-content: space-between;
    margin-top: 20px;
  }

  .view {
    .icon {
      margin-right: 20px;
      cursor: pointer;
      color:$black-light;

      @media screen and (max-width: $screen-md) {
        font-size: 40px;
      }

      &.active {
        cursor: default;
        opacity: 0.2;
      }
    }
  }

  .sort {
    display: flex;
  }

  .arrows {
    width: 20px;
    margin-left: 10px;
    position: relative;
    cursor: pointer;

    button {
      height: 12px;
    }

    button:focus::after {
      opacity: 0;
    }

    i {
      color: $black-light
    }

    .arrow-up,
    .arrow-down {
      position: absolute;
    }

    .arrow-up {
      top: -6px;
    }

    .arrow-down {
      bottom: -6px;
    }
  }
}

.events {
  min-height: 130px;
  margin-top: 30px;

  &.grid {
    padding: 0 50px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -18px;

    @media screen and (max-width: $screen-md) {
      justify-content: center;
      padding: 0 20px;
    }
  }
}

.event {
  margin-bottom: 8px;
}

.event-grid {
  margin: 0 18px 18px;
}
</style>
