import { publicBKApi } from '@/plugins/axios'

export const getUser = async(userId) => {
  try {
    const { data } = await publicBKApi.get(`/user/${userId}`, { params: { o: process.env.VUE_APP_ORG_ID } })
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) {
      return null
    }
  }
}

export const getUsersCount = async() => {
  try {
    const params = {
      o: process.env.VUE_APP_ORG_ID
    }
    const { data } = await publicBKApi.get('/users/count', { params })
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) {
      return null
    }
  }
}

export const getLeaderboard = async({
  pageNumber: p = 0,
  pageSize: pp = 8,
  keyword: k = '',
  sortBy = 'rank',
  sortDir = 'asc',
  year: y
}) => {
  const params = {
    p, pp, k, sb: `${sortBy}-${sortDir}`, y
  }
  const { data } = await publicBKApi.get(`/orgs/${process.env.VUE_APP_ORG_ID}/leaderboard`, { params })
  return data
}

export const getWinners = async() => {
  try {
    const params = { p: 0, pp: 8 }
    const { data } = await publicBKApi.get(`/orgs/${process.env.VUE_APP_ORG_ID}/winners`, { params })
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) {
      return null
    }
  }
}

export const subscibeNewsletter = async(email) => {
  try {
    const params = { email }
    const { data } = await publicBKApi.post('/udef-newsletter', null, { params })
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) {
      return null
    }
  }
}
