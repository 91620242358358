<template>
  <article
    class="user-wrapper"
    @mouseenter="isShowTooltip = true"
    @mouseleave="isShowTooltip = false"
  >
    <transition name="fade">
      <div v-if="isShowTooltip" class="tooltip">
        <div class="tooltip-wrapper">
          <div class="name">
            <h3 v-if="isShowRank">{{ user.rank }}</h3>
            <h3>{{ user.dancerName }}</h3>
          </div>

          <div class="address">
            <span class="country">{{ user.country }}</span>
            <span class="city">{{ user.city }}</span>
          </div>

          <UdefButton
            :to="{ name: 'Profile', params: { id: user.id} }"
            class="view-btn"
            color="primary"
            width="184"
            height="40"
          >
            View profile
          </UdefButton>

          <div v-if="isShowRank" class="trophy">
            <img
              v-if="isUserInTop1"
              alt="place trophy"
              class="img"
              src="/img/icons/winner-top-1.svg"
            >
            <img
              v-else-if="isUserInTop10"
              alt="place trophy"
              class="img"
              src="/img/icons/winner-top-10.svg"
            >
            <img
              v-else
              alt="place trophy"
              class="img"
              src="/img/icons/winner-top-100.svg"
            >
          </div>
        </div>
      </div>
    </transition>
    <article
      :style="`background: center/ cover no-repeat url(${getAvatar(user.avatar, 305, 385)})`"
      class="user"
      @click="isShowTooltip = !isShowTooltip"
    >
      <v-img
        class="flag"
        width="32"
        height="20"
        alt=""
        :src="getFlag(user.country)"
      />

      <div class="information">
        <div v-if="isShowRank" class="place">
          {{ user.rank }}
        </div>
        <div class="name">
          {{ user.dancerName }}
        </div>
      </div>
    </article>
  </article>
</template>

<script>
import Images from '@/mixins/image'

export default {
  name: 'UdefUserCardVertical',
  mixins: [Images],
  props: {
    user: {
      type: Object,
      required: true
    },
    isShowRank: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShowTooltip: false
    }
  },
  computed: {
    isUserInTop1() {
      return this.user.rank === 1
    },
    isUserInTop10() {
      return this.user.rank <= 10
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.user-wrapper {
  position: relative;
  max-width: 305px;
  width: 100%;

  @media screen and (max-width: $screen-md) {
    max-width: 335px;
  }
}

.user {
  height: 385px;
  cursor: pointer;
  position: relative;

  @media screen and (max-width: $screen-md) {
    height: 423px;
  }

  &::after {
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    background: $shadow-gradient;
  }
}

.flag {
  position: absolute;
  top: 12px;
  right: 12px ;
}

.information {
  left: 16px;
  bottom: 16px;
  position: absolute;
  color: $white;
  font-size: $font-big;
  font-weight: $font-weight-bold;
}

.tooltip {
  box-shadow: $article-shadow;
  position: absolute;
  background-color: $white;
  border-radius: 0;
  padding: 20px;
  bottom: -10px;
  left: -10px;
  color: $black-light;
  z-index: 1;

  .tooltip-wrapper {
    position: relative;
  }

  .trophy {
    position: absolute;
    top: 3px;
    right: 0;

    .img {
      opacity: 0.6;
      width: 24px;
      height: 24px;
    }
  }

  .name {
    position: relative;
    z-index: 1;
    line-height: 30px;

    h3 {
    font-size: $font-big;
    }
  }

  .address {
    margin-top: 8px;

    .country {
      color: $grey;
      font-size: $font-small;
      display: block;
    }

    .city {
      display: block;
    }
  }

  .view-btn {
    margin-top: 20px;
  }
}
</style>
