<template>
  <div class="background">
    <section class="behind udef-content">
      <h2 class="behind-title">
        Who we are
      </h2>
      <p class="behind-subtitle">
        A small blurb about the people behind UDEF
      </p>

      <div class="members">
        <AboutMemberCard
          v-for="(member, index) in members"
          :key="index"
          class="member"
          :member="member"
        />
      </div>
    </section>
  </div>
</template>

<script>
import AboutMemberCard from '@/components/AboutMemberCard'

export default {
  name: 'AboutPeoplesBehind',
  components: {
    AboutMemberCard
  },
  data() {
    return {
      members: [
        {
          img: '/img/member-1.webp',
          name: 'Joe Stolte',
          role: 'CFO',
          desc: '',
          socials: [
            {
              name: 'instagram',
              link: 'https://www.instagram.com/joestoltelive'
            },
            {
              name: 'facebook',
              link: 'https://www.facebook.com/likejoestolte'
            },
            {
              name: 'twitter',
              link: 'https://twitter.com/joestoltelive'
            }
          ]
        },
        {
          img: '/img/member-2.webp',
          name: 'Chris Wright',
          role: 'President',
          desc: '',
          socials: [
            {
              name: 'instagram',
              link: 'https://www.instagram.com/iamcrosone'
            },
            {
              name: 'facebook',
              link: 'https://www.facebook.com/iamcros1'
            },
            {
              name: 'twitter',
              link: 'https://twitter.com/IamCROS1'
            }
          ]
        },
        {
          img: '/img/member-3.webp',
          name: 'Luis Espinosa',
          role: 'Tour Manager',
          desc: '',
          socials: [
            {
              name: 'instagram',
              link: ' https://www.instagram.com/bboysweetlu'
            },
            {
              name: 'twitter',
              link: 'https://www.twitter.com/bboysweetlu'
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';
.background {
  background-color: $light;
}

.behind {
  color: $black-light;
  padding: 77px 50px 120px 50px;

  @media screen and (max-width: $screen-md) {
   padding: 40px 20px;
  }
}

.behind-title {
  font-size: $font-section-title;
  text-align: center;
  font-weight: $font-weight-bold;

  @media screen and (max-width: $screen-md) {
   font-size: $font-section-title-mobile;
   margin-bottom: 20px;
  }
}

.behind-subtitle {
  text-align: center;

  @media screen and (max-width: $screen-md) {
   display: none;
  }
}

.members {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: $screen-md) {
    justify-content: center;
  }

  .member {
    margin-bottom: 20px;
  }
}
</style>
