<template>
  <router-link tag="article" :to="{name: 'Event', params: { id: event.id} }" class="event">
    <div>
      <img class="img" :src="getEventImg(event.image, 840)" alt="Event">
    </div>
    <div class="content">
      <div class="information">
        <div class="date">
          {{ eventDate }}
        </div>

        <template v-if="event.locationAddress">
          <div  class="separator">
            |
          </div>
          <div  class="location">
            {{ event.locationAddress }}
          </div>
        </template>

      </div>
      <div class="name">
        {{ event.title }}
      </div>
    </div>
  </router-link>
</template>

<script>
import Images from '@/mixins/image'
import Dates from '@/mixins/date'

export default {
  name: 'UdefEventCardHorizontal',
  mixins: [Images, Dates],
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    eventDate() {
      return new Date(this.event.dateStart).toLocaleDateString('en-US')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.event {
  box-shadow: $horizontal-card-shadow;
  padding: 20px 50px;
  display: flex;
  cursor: pointer;
}

.img {
  width: 75px;
  height: 75px;
  margin-right: 16px;
}

.content {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .information {
    display: flex;
    color: $grey-light;

    @media screen and (max-width: $screen-md) {
      flex-direction: column;
    }

    .separator {
      margin: 0 8px;

      @media screen and (max-width: $screen-md) {
        display: none;
      }
    }
  }

  .name {
    font-weight: $font-weight-bold;
    color: $black-light
  }
}

</style>
