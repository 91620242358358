<template>
  <article class="card">
    <v-img class="img" :src="getPreviewImageUrl(media.link)" @click="showMedia">
      <UdefIcon class="play-icon" color="#FFFFFF" icon="play" />
    </v-img>
    <span class="name">
      {{ media.name }}
    </span>
  </article>
</template>

<script>
export default {
  name: 'UdefMediaCard',
  props: {
    media: {
      type: Object,
      required: true
    }
  },
  methods: {
    getPreviewImageUrl(id) {
      return `https://img.youtube.com/vi/${id}/0.jpg`
    },
    showMedia() {
      this.$emit('show', this.media.link)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.img {
  position: relative;
  cursor: pointer;
  height: 172px;
  max-width: 305px;
  width: 100%;

  @media screen and (max-width: $screen-md) {
    height: 189px;
    max-width: 335px;
  }

  &::after {
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    background: $black;
    opacity: 0.3;
  }
}

.play-icon {
  z-index: 1;
  position: absolute;
  right: 15px;
  top: 15px;
}

.name {
  display: block;
  margin-top: 12px;
  font-size: $font-small;
  color: $grey-light;

  @media screen and (max-width: $screen-md) {
    margin-top: 8px;
  }
}
</style>
