import { publicBKApi } from '@/plugins/axios'

export const getEvents = async({ pageNumber, pageSize, filter, timeFilter, keyword, sortBy, sortDir, userId }) => {
  try {
    const params = {
      p: pageNumber,
      pp: pageSize,
      t: timeFilter,
      o: process.env.VUE_APP_ORG_ID,
      k: keyword,
      s: `${sortBy}-${sortDir}`,
      u: userId
    }

    const { data } = await publicBKApi.get('events', { params })
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) { return null }
  }
}

export const getEvent = async(eventId) => {
  try {
    const { data } = await publicBKApi.get(`events/${eventId}`)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) { return null }
  }
}

export const getEventBattles = async(eventId) => {
  try {
    const { data } = await publicBKApi.get(`event/${eventId}/battles`)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) { return null }
  }
}

export const getBattleStaff = async(battleId) => {
  try {
    const { data } = await publicBKApi.get(`battle/${battleId}/staff`)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getBattleParticipants = async(battleId) => {
  try {
    const { data } = await publicBKApi.get(`battle/${battleId}/participants`)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getBattleCrews = async(battleId) => {
  try {
    const { data } = await publicBKApi.get(`battle/${battleId}/crews`)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getEventStaff = async(eventId) => {
  try {
    const { data } = await publicBKApi.get(`event/${eventId}/staff`)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) { return null }
  }
}

export const getEventParticipants = async(eventId) => {
  try {
    const { data } = await publicBKApi.get(`event/${eventId}/participants`)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) { return null }
  }
}

export const getEventsAvailableTabs = async() => {
  const params = { orgs: process.env.VUE_APP_ORG_ID }

  try {
    const { data } = await publicBKApi.get('events/tabs', { params })
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}
