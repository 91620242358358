<template>
  <section class="privacy">
    <h1 class="privacy-title">
      Privacy policy
    </h1>
    <h3 class="paragraph-title">
      WHO WE ARE
    </h3>
    <p>
      Our website address is https://www.myudeftour.org.
    </p>
    <p>
      Website Privacy Statement
    </p>

    <p>
      Last Updated: February 21, 2019
    </p>

    <p>
      Thank you for choosing to be part of our community at Urban Dance &
      Educational Foundation, doing business as UDEF (“UDEF”, “we”, “us”, or
      “our”). We are committed to protecting your personal information and your
      right to privacy.
    </p>

    <p>
      This privacy policy applies to all information collected through our
      website http://s790379109.onlinehome.us, and/or any related services,
      marketing or events (we refer to them collectively in this privacy policy
      as the “Sites”).
    </p>

    <p>
      When you visit our website http://s790379109.onlinehome.us, and use our
      services, you trust us with your personal information. We take your
      privacy very seriously. In this privacy notice, we describe our privacy
      policy. We seek to explain to you in the clearest way possible what
      information we collect, how we use it and what rights you have in relation
      to it.
    </p>

    <p>
      IF YOU ARE UNDER 16 (SIXTEEN) YEARS OLD, PLEASE TALK TO YOUR PARENTS
      BEFORE USING THE SITES OR PROVIDING YOUR NAME OR OTHER PERSONAL
      INFORMATION TO THE SITES. YOU CANNOT SUBMIT INFORMATION TO THE SITES
      WITHOUT YOUR PARENT’S PERMISSION (OR A PERSON WITH PARENTAL RESPONSIBILITY
      FOR YOU).
    </p>

    <p>
      Please read this privacy policy carefully as it will help you make
      informed decisions about sharing your personal information with us. If
      there are any terms in this privacy policy that you do not agree with,
      please discontinue use of our Sites. If you have any questions or concerns
      about our policy, or our practices with regards to your personal
      information, please contact us
      http://s790379109.onlinehome.us/legal/privacy-policy/privacy-questions.
    </p>

    <h3 class="paragraph-title">
      TABLE OF CONTENTS
    </h3>

    <ol>
      <li>
        WHAT INFORMATION DO WE COLLECT?
      </li>
      <li>
        HOW DO WE USE YOUR INFORMATION?
      </li>
      <li>
        WILL YOUR INFORMATION BE SHARED WITH ANYONE?
      </li>
      <li>
        WHO WILL YOUR INFORMATION BE SHARED WITH?
      </li>
      <li>
        DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
      </li>
      <li>
        IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
      </li>
      <li>
        WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
      </li>
      <li>
        HOW LONG DO WE KEEP YOUR INFORMATION?
      </li>
      <li>
        HOW DO WE KEEP YOUR INFORMATION SAFE?
      </li>
      <li>
        WHAT ARE YOUR PRIVACY RIGHTS?
      </li>
      <li>
        CONTROLS FOR DO-NOT-TRACK FEATURES
      </li>
      <li>
        DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
      </li>
      <li>DO WE MAKE UPDATES TO THIS POLICY?</li>
      <li>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</li>
    </ol>

    <h3 class="paragraph-title">
      WHAT INFORMATION DO WE COLLECT?
    </h3>

    <p>
      Information you give to us:
    </p>

    <p>
      In Short: We collect personal information that you provide to us such as
      name, address, contact information, passwords and security data, and
      payment information.
    </p>

    <p>
      You may visit our website without submitting any information about
      yourself. However, we do collect personal information that you voluntarily
      provide to us when registering at the Sites, expressing an interest in
      obtaining information about us or our services, when participating in
      activities on the Sites or otherwise contacting us. This includes:
    </p>
    <ul>
      <li>
        Registering as a member of UDEF (whether Supporter, Competitor or
        otherwise)
      </li>
      <li>Registering for UDEF’s communications</li>
      <li>Participating at a UDEF-sanctioned activity or event</li>
      <li>Purchasing products or services from UDEF</li>
      <li>
        Participating in member profiles and directories, message boards, chat
        rooms or other online services where registration is required
      </li>
      <li>Communicating by any other means to UDEF</li>
      <li>
        The personal information that we collect depends on the context of your
        interactions with us and the Sites, the choices you make and the
        products and features you use.
      </li>
      <li>The personal information we collect can include the following:</li>
    </ul>

    <ul>
      <li>
        Name and Contact Data. We collect your first and last name, email
        address, postal address, phone number, and other similar contact data.
      </li>
      <li>
        Biographical Data. We collect your birthday, country of citizenship,
        name and contact data for your emergency contact, and if you are under
        18 at the time of registration with us, your parent’s or guardian’s
        first and last name, email address, and phone number.
      </li>

      <li>
        Credentials. We collect passwords, password hints, and similar security
        information used for authentication and account access.
      </li>

      <li>
        Payment Data. We collect data necessary to process your payment if you
        make purchases or donations, such as your payment instrument number
        (such as a credit card number), and the security code associated with
        your payment instrument. All payment data is stored by our payment
        processor and you should review its privacy policies and contact the
        payment processor directly to respond to your questions.
      </li>

      <li>
        All personal information that you provide to us must be true, complete
        and accurate, and you must notify us of any changes to such personal
        information.
      </li>
    </ul>

    <p>Information we collect automatically:</p>

    <p>
      In Short: Some information – such as IP address and/or browser and device
      characteristics – is collected automatically when you visit our Sites.
    </p>

    <p>
      We use cookies and similar technologies on our online services, electronic
      communications and advertisements that automatically receive and track
      certain data about how you and other visitors interact with our online
      services, preferences expressed, and settings chosen.
    </p>

    <p>
      For example, we may collect information about your device (such as your
      device and browser type, operating system, IP address, mobile network
      carrier, and device and advertising identifiers), and your use of our
      online services (such as access dates and times, online features or pages
      viewed, system activity and the third-party site or service you were using
      before interacting with our online services). In some cases, we do this
      through the use of cookies, pixel tags, SDKs and similar technologies that
      create and maintain unique identifiers.
    </p>

    <p>
      Information collected from other sources
    </p>

    <p>
      In Short: We may collect limited data from public databases, marketing
      partners, event organizers, and other outside sources.
    </p>

    <p>
      We may obtain information about you from other sources, such as public
      databases, marketing partners, event organizers, as well as from other
      third parties. Examples of the information we receive from other sources
      include: social media profile information; results of competitions.
    </p>

    <h3 class="paragraph-title">
      HOW DO WE USE YOUR INFORMATION?
    </h3>

    <p>
      In Short: We process your information for purposes based on legitimate
      business interests, the fulfillment of our agreement with you, compliance
      with our legal obligations, and/or your consent.
    </p>

    <p>
      We use personal information collected via our Sites for a variety of
      business purposes described below. We process your personal information
      for these purposes in reliance on our legitimate business interests, in
      order to enter into or perform an agreement with you, with your consent,
      and/or for compliance with our legal obligations. We indicate the specific
      processing grounds we rely on next to each purpose listed below.
    </p>

    <p>
      To facilitate account creation and logon process. If you choose to link
      your account with us to a third-party account such as your Google or
      Facebook account, we use the information you allowed us to collect from
      those third parties to facilitate account creation and logon process. To
      send you marketing and promotional communications. We and/or our
      third-party marketing partners may use the personal information you send
      to us for our marketing purposes, if this is in accordance with your
      marketing preferences. You can opt-out of our marketing emails at any time
      (see the ” WHAT ARE YOUR PRIVACY RIGHTS ” below). To send administrative
      information to you. We may use your personal information to send you
      product, service and new feature information and/or information about
      changes to our terms, conditions, and policies. Administer competitions.
      We may use your information to administer competitions when you elect to
      participate in competitions. Request Feedback. We may use your information
      to request feedback and to contact you about your use of our Sites. To
      protect our Sites. We may use your information as part of our efforts to
      keep our Sites safe and secure (for example, for fraud monitoring and
      prevention). To enforce our terms, conditions and policies.
    </p>

    <p>
      To respond to legal requests and prevent harm. If we receive a subpoena or
      other legal request, we may need to inspect the data we hold to determine
      how to respond. For other Business Purposes. We may use your information
      for other Business Purposes, such as data analysis, identifying usage
      trends, determining the effectiveness of our promotional campaigns and to
      evaluate and improve our Sites, products, services, marketing and your
      experience.
    </p>

    <h3 class="paragraph-title">
      WILL YOUR INFORMATION BE SHARED WITH ANYONE?
    </h3>

    <p>
      In Short: We only share information with your consent, to comply with
      laws, to protect your rights, or to fulfill business obligations.
    </p>
    <p>
      We may process or share data based on the following legal basis:
    </p>

    <p>
      Consent: We may process your data if you have given us specific consent to
      use your personal information in a specific purpose. Legitimate Interests:
      We may process your data when it is reasonably necessary to achieve our
      legitimate business interests. Performance of an agreement: Where we have
      entered into an agreement with you, we may process your personal
      information to fulfill the terms of our agreement. Legal Obligations: We
      may disclose your information where we are legally required to do so in
      order to comply with applicable law, governmental requests, a judicial
      proceeding, court order, or legal process, such as in response to a court
      order or a subpoena (including in response to public authorities to meet
      national security or law enforcement requirements). Vital Interests: We
      may disclose your information where we believe it is necessary to
      investigate, prevent, or take action regarding potential violations of our
      policies, suspected fraud, situations involving potential threats to the
      safety of any person and illegal activities, or as evidence in litigation
      in which we are involved. More specifically, we may need to process your
      data or share your personal information in the following situations:
    </p>

    <p>
      Vendors, Consultants and Other Third-Party Service Providers. We may share
      your data with third party vendors, service providers, contractors or
      agents who perform services for us or on our behalf and require access to
      such information to do that work. Examples include: payment processing,
      data analysis, email delivery, hosting services, customer service and
      marketing efforts. We may allow selected third parties to use tracking
      technology on the Sites, which will enable them to collect data about how
      you interact with the Sites over time. This information may be used to,
      among other things, analyze and track data, determine the popularity of
      certain content and better understand online activity. Unless described in
      this Policy, we do not share, sell, rent or trade any of your information
      with third parties for their promotional purposes. Business Transfers. We
      may share or transfer your information in connection with, or during
      negotiations of, any merger, sale of company assets, financing, or
      acquisition of all or a portion of our business to another company.
      Third-Party Advertisers. We may use third-party advertising companies to
      serve ads when you visit the Sites. These companies may use information
      about your visits to our Sites and other websites that are contained in
      web cookies and other tracking technologies in order to provide
      advertisements about goods and services of interest to you.
    </p>

    <p>
      Affiliates. We may share your information with our affiliates, in which
      case we will require those affiliates to honor this privacy policy.
      Affiliates include our parent company and any subsidiaries, join venture
      partners or other companies that we control or that are under common
      control with us. Business Partners. We may share your information with our
      business partners to offer you certain products, services or promotions.
      We may generate aggregate or de-identified data that does not contain your
      personally identifiable information. We are not restricted in use of such
      data.
    </p>

    <h3 class="paragraph-title">
      WHO WILL YOUR INFORMATION BE SHARED WITH?
    </h3>

    <p>
      In Short: We share information with third parties.
    </p>
    <p>
      We share and disclose your information with third parties including but
      not limited to those listed below. We have categorized each party so that
      you may be easily understand the purpose of our data collection and
      processing practices. If we have processed your data based on your consent
      and you wish to revoke your consent, please contact us.
    </p>

    <p>
      Advertising, Direct Marketing, and Lead Generation: Google AdSense Allow
      Users to Connect to their Third-Party Accounts: Facebook account,
      Instagram account and Youtube account Communicate and Chat with Users:
      MailChimp, Mandrill and Zendesk Chat Content Optimization: Instagram
      embed, SoundCloud embedded player, Vimeo video, YouTube video embed and
      Google Fonts Data Backup and Security: Updraft Invoice and Billing: Stripe
      and Donately Social Media Sharing and Advertising: AddThis User Account
      Registration and Authentication: Facebook Login, Google OAuth 2.0,
      Instagram Authentication and WordPress Single Sign On User Commenting and
      Forums: Web and Mobile Analytics: Facebook Analytics, Google Analytics and
      Google Tag Manager Website Hosting: Ionos Website Performance Monitoring:
      New Relic Website Testing : Google Website Optimizer
    </p>

    <h3 class="paragraph-title">
      DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
    </h3>

    <p>
      In Short: We may use cookies and other tracking technologies to collect
      and store your information.
    </p>

    <p>
      We may use cookies and similar tracking technologies (like web beacons and
      pixels) to access or store information. Specific information about how we
      use such technologies and how you can refuse certain cookies is set out in
      our Cookie Policy.
    </p>

    <h3 class="paragraph-title">
      IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
    </h3>

    <p>
      In Short: We may transfer, store, and process your information in
      countries other than your own.
    </p>

    <p>
      Our servers are located in United States. If you are accessing our Sites
      from outside United States, please be aware that your information may be
      transferred to, stored, and processed by us in our facilities and by those
      third parties with whom we may share your personal information (see ” WILL
      YOUR INFORMATION BE SHARED WITH ANYONE? ” above), in the United States and
      in other countries.
    </p>

    <p>
      If you are a resident in the European Economic Area, then these countries
      may not have data protection or other laws as comprehensive as those in
      your country. We will however take all necessary measures to protect your
      personal information in accordance with this privacy policy and applicable
      law.
    </p>

    <p>
      In particular we comply with the EU-U.S. Privacy Shield Framework as set
      forth by the U.S. Department of ommerce regarding the collection, use, and
      retention of personal information transferred from the European Union to
      the United States and has certified its compliance with it. As such, we
      are committed to subjecting all personal information received from
      European Union (EU) member countries, in reliance on the Privacy Shield
      Framework, to the Framework’s applicable Principles. To learn more about
      the Privacy Shield Framework, visit the U.S. Department of Commerce’s
      Privacy Shield List.
    </p>

    <p>
      Company is responsible for the processing of personal information it
      receives, under the Privacy Shield Framework, and subsequently transfers
      to a third party acting as an agent on its behalf.
    </p>

    <p>
      With respect to personal information received or transferred pursuant to
      the Privacy Shield Framework, Company is subject to the regulatory
      enforcement powers of the U.S. FTC. In certain situations, we may be
      required to disclose personal information in response to lawful requests
      by public authorities, including to meet national security or law
      enforcement requirements.
    </p>

    <h3 class="paragraph-title">
      WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
    </h3>

    <p>
      In Short: We are not responsible for the safety of any information that
      you share with third-parties who advertise or about whom we post links to,
      but are not affiliated with, our Sites.
    </p>

    <p>
      The Sites may contain advertisements from or posts about third parties
      that are not affiliated with us and which may link to other websites,
      online services or mobile applications. We cannot guarantee the safety and
      privacy of data you provide to any third parties. Any data collected by
      third parties is not covered by this privacy policy. We are not
      responsible for the content or privacy and security practices and policies
      of any third parties, including other websites, services or applications
      that may be linked to or from the Sites. You should review the policies of
      such third parties and contact them directly to respond to your questions.
    </p>

    <h3 class="paragraph-title">
      HOW LONG DO WE KEEP YOUR INFORMATION?
    </h3>

    <p>
      In Short: We keep your information for as long as necessary to fulfill the
      purposes outlined in this privacy policy unless otherwise required by law.
    </p>
    <p>
      We will only keep your personal information for as long as it is necessary
      for the purposes set out in this privacy policy, unless a longer retention
      period is required or permitted by law (such as tax, accounting or other
      legal requirements). No purpose in this policy will require us keeping
      your personal information for longer than 2 years past the termination of
      the user’s account.
    </p>

    <p>
      When we have no ongoing legitimate business need to process your personal
      information, we will either delete or anonymize it, or, if this is not
      possible (for example, because your personal information has been stored
      in backup archives), then we will securely store your personal information
      and isolate it from any further processing until deletion is possible.
    </p>

    <h3 class="paragraph-title">
      HOW DO WE KEEP YOUR INFORMATION SAFE?
    </h3>

    <p>
      In Short: We aim to protect your personal information through a system of
      organizational and technical security measures.
    </p>

    <p>
      We have implemented appropriate technical and organizational security
      measures designed to protect the security of any personal information we
      process. However, please also remember that we cannot guarantee that the
      internet itself is 100% secure. Although we will do our best to protect
      your personal information, transmission of personal information to and
      from our Sites is at your own risk. You should only access the services
      within a secure environment.
    </p>

    <h3 class="paragraph-title">
      WHAT ARE YOUR PRIVACY RIGHTS?
    </h3>

    <p>
      In Short: In some regions, such as the European Economic Area, you have
      rights that allow you greater access to and control over your personal
      information. You may review, change, or terminate your account at any
      time.
    </p>

    <p>
      In some regions (like the European Economic Area), you have certain rights
      under applicable data protection laws. These may include the right (i) to
      request access and obtain a copy of your personal information, (ii) to
      request rectification or erasure; (iii) to restrict the processing of your
      personal information; and (iv) if applicable, to data portability. In
      certain circumstances, you may also have the right to object to the
      processing of your personal information. To make such a request, please
      use the contact details provided below. We will consider and act upon any
      request in accordance with applicable data protection laws.
    </p>

    <p>
      If we are relying on your consent to process your personal information,
      you have the right to withdraw your consent at any time. Please note
      however that this will not affect the lawfulness of the processing before
      its withdrawal.
    </p>

    <p>
      If you are resident in the European Economic Area and you believe we are
      unlawfully processing your personal information, you also have the right
      to complain to your local data protection supervisory authority. You can
      find their contact details here:
      http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
    </p>

    <p>
      Account Information: If you would at any time like to review or change the
      information in your account or terminate your account, you can log into
      your account settings and update your user account. Upon your request to
      terminate your account, we will deactivate or delete your account and
      information from our active databases. However, some information may be
      retained in our files to prevent fraud, troubleshoot problems, assist with
      any investigations, enforce our Terms of Use and/or comply with legal
      requirements.
    </p>

    <p>
      Cookies and similar technologies: Most web browsers are set to accept
      cookies by default. If you prefer, you can usually choose to set your
      browser to remove cookies and to reject cookies. If you choose to remove
      cookies or reject cookies, this could affect certain features or services
      of our Sites. To opt-out of interest-based advertising by advertisers on
      our Sites visit http://www.aboutads.info/choices/ .
    </p>

    <p>
      Opting out of email marketing: You can unsubscribe from our marketing
      email list at any time by clicking on the unsubscribe link in the emails
      that we send or by contacting us using the details provided below. You
      will then be removed from the marketing email list – however, we will
      still need to send you service-related emails that are necessary for the
      administration and use of your account.
    </p>

    <h3 class="paragrah-title">
      CONTROLS FOR DO-NOT-TRACK FEATURES
    </h3>

    <p>In Short: Our Sites can’t honor do-not-track requests at this time.</p>

    <p>
      Most web browsers and some mobile operating systems and mobile
      applications include a Do-Not-Track (“DNT”) feature or setting you can
      activate to signal your privacy preference not to have data about your
      online browsing activities monitored and collected. No uniform technology
      standard for recognizing and implementing DNT signals has been finalized.
      As such, we do not currently respond to DNT browser signals or any other
      mechanism that automatically communicates your choice not to be tracked
      online. If a standard for online tracking is adopted that we must follow
      in the future, we will inform you about that practice in a revised version
      of this Privacy Policy.
    </p>

    <h3 class="paragraph-title">
      DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
    </h3>

    <p>
      In Short: Yes, if you are a resident of California, you are granted
      specific rights regarding access to your personal information.
    </p>

    <p>
      California Civil Code Section 1798.83, also known as the “Shine The Light”
      law, permits our users who are California residents to request and obtain
      from us, once a year and free of charge, information about categories of
      personal information (if any) we disclosed to third parties for direct
      marketing purposes and the names and addresses of all third parties with
      which we shared personal information in the immediately preceding calendar
      year. If you are a California resident and would like to make such a
      request, please submit your request in writing to us using the contact
      information provided below.
    </p>

    <p>
      If you are under 18 years of age, reside in California, and have a
      registered account with the Sites, you have the right to request removal
      of unwanted data that you publicly post on the Sites. To request removal
      of such data, please contact us using the contact information provided
      below, and include the email address associated with your account and a
      statement that you reside in California. We will make sure the data is not
      publicly displayed on the Sites, but please be aware that the data may not
      be completely or comprehensively removed from our systems.
    </p>

    <h3 class="paragraph-title">
      DO WE MAKE UPDATES TO THIS POLICY?
    </h3>

    <p>
      In Short: Yes, we will update this policy as necessary to stay compliant
      with relevant laws.
    </p>

    <p>
      We may update this privacy policy from time to time. The updated version
      will be indicated by an updated “Revised” date and the updated version
      will be effective as soon as it is accessible. If we make material changes
      to this privacy policy, we may notify you either by prominently posting a
      notice of such changes or by directly sending you a notification. We
      encourage you to review this privacy policy frequently to be informed of
      how we are protecting your information.
    </p>

    <h3 class="paragraph-title">
      HOW CAN YOU CONTACT US ABOUT THIS POLICY?
    </h3>

    <p>
      If you have questions or comments about this policy, you may contact our
      Data Protection Officer (DPO), Chris Wright, at
      <a
        target="_blank"
        href="http://s790379109.onlinehome.us/legal/privacy-policy/privacy-questions"
      >
        http://s790379109.onlinehome.us/legal/privacy-policy/privacy-questions</a>, or by post to:
    </p>

    <ul>
      <li>Urban Dance & Educational Foundation</li>
      <li>Attn: Chris Wright, Data Protection Officer</li>
      <li>8810 Jamacha Blvd Ste C # 156</li>
      <li>Spring Valley, CA 91977-5615</li>
      <li>USA</li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/global.scss";

.privacy {
  max-width: 880px;
  margin: auto;
  padding-top: 80px;

  @media screen and (max-width: $screen-md) {
    padding: 40px 20px 0 20px
  }

  .privacy-title,
  .paragraph-title,
  p,
  ul,
  ol {
    margin-bottom: 20px;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  .paragraph-title {
    font-weight: $font-weight-bold;
  }

  .privacy-title {
    font-size: $font-section-title;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $screen-md) {
      font-size: $font-section-title-mobile;
    }
  }
}
</style>
