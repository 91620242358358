<template>
  <v-dialog
    v-model="isShowModal"
    overlay-color="#000000"
    overlay-opacity="0.9"
    content-class="udef-modal"
    :fullscreen="fullscreen"
    :max-width="maxWidth"
  > 
    <section :class="{ fullscreen: fullscreen }" class="close">
      <div class="close-btn" @click="isShowModal = false">
        <v-icon>
          mdi-close
        </v-icon> <span>Close</span>
      </div>
    </section>
    <slot />
  </v-dialog>
</template>

<script>
export default {
  name: 'UdefModal',
  props: {
    isShow: {
      type: Boolean,
      required: true
    },
    maxWidth: {
      type: String,
      default: '830'
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShowModal: this.isShow
    }
  },
  watch: {
    isShowModal() {
      this.$emit('update:isShow', this.isShowModal)
    }
  }
}
</script>

<style lang="scss">
.udef-modal.v-dialog.v-dialog--active {
  border-radius: 0;
}
</style>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.close {
  position: absolute;
  z-index: 1;
  margin-top: -55px;
  color: $white;
  padding: 20px 0;
  display: flex;
  align-items: center;

  &.fullscreen {
    padding: 20px 30px;
    position: unset;
    background-color: $black-light;
    opacity: 0.90;
    margin: 0;
  }

  .close-btn {
    cursor: pointer;
  }

  i {
    color: $white;
    margin-left: 2px;
  }
}
</style>
