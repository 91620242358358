<template>
  <section>
    <UdefHero img="/img/hero-about.webp">
      <div class="hero-title">
        <span>Thank You!</span>
      </div>
    </UdefHero>
    <section class="donation">
      <p class="text">
        {{ donationMessage }}
      </p>

      <div class="share">
        <span>Share the donation link</span>
        <div class="socials">
          <a target="_blank" :href="facebookShareUrl">
            <img src="/img/socials/facebook-grey.svg" alt="facebook">
          </a>
          <a target="_blank" :href="twitterShareUrl">
            <img src="/img/socials/twitter-grey.svg" alt="twitter">
          </a>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name: 'DonationThank',
  computed: {
    donatorName() {
      return this.$route.query.name
    },
    donationAmount() {
      return this.$route.query.amount
    },
    donationMessage() {
      return `“${this.donatorName}”, thank you for donating $${this.donationAmount} to the
        Urban Dance Education Foundation. We are thankful to for your donation!`
    },
    twitterShareUrl() {
      const twitterUrl = 'https://twitter.com/intent/tweet'
      const shareUrl = `${twitterUrl}?text=${this.donationMessage}&url=${window.location.href}`
      return shareUrl
    },
    facebookShareUrl() {
      const facebookUrl = 'https://www.facebook.com/sharer/sharer.php'
      const shareUrl = `${facebookUrl}?u=${window.location.href}`
      return shareUrl
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/global.scss";

.hero-title {
  min-height: 650px;
  font-size: $font-hero-title;
  font-weight: $font-weight-bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;

  @media screen and (max-width: $screen-md) {
    min-height: 268px;
    font-size: $font-section-title-mobile;
  }
}

.donation {
  max-width: 880px;
  margin: auto;

  .text {
    padding: 60px 20px 40px 20px;
    text-align: center;
    font-size: $font-bigger;
    font-weight: $font-weight-bold;
  }
}

.share {
  text-align: center;
  color: $grey-light;
  padding-bottom: 100px;
}

.socials {
  margin-top: 10px;
  color: $grey-light;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 20px;
  }
}
</style>
