<template>
  <article class="member">
    <div :style="`background: center/ cover no-repeat url(${member.img})`" class="image" :src="member.img">
      <div class="information">
        <div class="name">
          {{ member.name }}
        </div>
        <div class="role">
          {{ member.role }}
        </div>
      </div>
    </div>

    <div class="description">
      {{ member.desc }}
    </div>

    <div class="socials">
      <a
        v-for="(social, index) in member.socials"
        :key="index"
        target="_blank"
        :href="social.link"
      ><img
        :src="`/img/socials/${social.name}.svg`"
        :alt="social.name"
      ></a>
    </div>
  </article>
</template>

<script>
export default {
  name: 'AboutMemberCard',
  props: {
    member: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.member {
  max-width: 420px;
  width: 100%;

  .image {
    max-width: 420px;
    width: 100%;
    height: 530px;
    position: relative;

    @media screen and (max-width: $screen-md) {
      height: 423px;
    }

    &::after {
      display: block;
      width: 100%;
      height: 100%;
      content: '';
      background: $shadow-gradient;
    }
  }

  .information {
    bottom: 20px;
    width: 100%;
    text-align: center;
    position: absolute;
    color: $white
  }

  .name {
    text-align: center;
    font-size: $font-big;
    font-weight: $font-weight-bold;
  }

  .role {
    font-size: $font-medium;
  }

  .description {
    margin-top: 21px;
    color: $black-light;
  }

  .socials {
    margin-top: 17px;
    display: flex;
    align-items: center;

    img {
      margin-right: 17px;
    }
  }
}

</style>
