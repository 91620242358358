<template>
  <section v-if="events.length" class="events udef-content">
    <h2 class="events-title">
      Upcoming Events
    </h2>

    <div class="events-grid">
      <UdefEventCardVertical
        v-for="(event, index) in events"
        :key="index"
        class="event"
        :event="event"
        large
      />
    </div>

    <div class="action">
      <UdefButton
        :to="{ name: 'Events'}"
        color="primary"
        outlined
        widht="190"
        height="52"
      >
        View all events
      </UdefButton>
    </div>
  </section>
</template>

<script>
import UdefEventCardVertical from '@/components/UdefEventCardVertical'

export default {
  name: 'IndexEvents',
  components: {
    UdefEventCardVertical
  },
  props: {
    events: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.events {
  color: $black-light;
  padding: 77px 48px;

  @media screen and (max-width: $screen-sm) {
   padding: 60px 20px;
  }
}

.events-title {
  font-size: $font-section-title;
  text-align: center;
  margin-bottom: 37px;
  font-weight: $font-weight-bold;

  @media screen and (max-width: $screen-sm) {
    font-size: $font-section-title-mobile;
  }
}

.events-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;

  @media screen and (max-width: $screen-md) {
   justify-content: center;
  }

  .event {
    margin: 0 20px 40px;

    @media screen and (max-width: $screen-md) {
      margin: 0 0 20px;
    }
  }
}

.action {
  text-align: center;
}
</style>
