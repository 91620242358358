<template>
  <v-menu
    v-model="isMenuOpen"
    nudge-width="242"
    offset-y
  >
    <template #activator="{ on, props }">
      <button
        class="filter-btn"
        v-bind="props"
        v-on="on"
      >
        <span>Year: {{ year || 'All Years' }}</span>
        <v-icon class="icon">
          {{ isMenuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </button>
    </template>
    <v-list class="filter-container">
      <v-list-item>
        <v-list-item-title class="filter-title">
          Year
        </v-list-item-title>
      </v-list-item>
      <v-radio-group v-model="year" class="mt-0">
        <v-list-item
          v-for="i in currentYear - lowerAnnualLimit + 1"
          :key="i"
        >
          <v-list-item-title
            class="filter-option"
            @click="$emit('update:year', currentYear - i + 1)"
          >
            {{ currentYear - i + 1 }}
            <v-radio
              :value="currentYear - i + 1"
              color="#041E42"
            />
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            class="filter-option"
            @click="$emit('update:year', null)"
          >
            All years
            <v-radio
              :value="null"
              color="#041E42"
            />
          </v-list-item-title>
        </v-list-item>
      </v-radio-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'FilterByYear',
  props: {
    year: {
      validator: prop => typeof prop === 'number' || prop === null,
      required: true
    }
  },
  data() {
    return {
      isMenuOpen: false,
      currentYear: new Date().getFullYear(),
      lowerAnnualLimit: 2021
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.filter-btn {
  display: flex;
  gap: 10px;
  align-items: center;

  .icon {
    color: $black-light;
  }
}

.filter-container {
  .v-list-item__title {
    &.filter-option {
      cursor: pointer;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &.filter-title {
      font-weight: $font-weight-bold;
    }
  }
}
</style>
