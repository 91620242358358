<template>
  <section class="news udef-content">
    <h2 class="news-title">
      What’s happening
    </h2>
    <div class="articles">
      <UdefArticleCard
        v-for="(article, index) in articles"
        :key="index"
        class="article"
        :article="article"
      />
    </div>
    <div class="action">
      <UdefButton
        :to="{name: 'Articles'}"
        width="190"
        height="52"
        outlined
        color="primary"
      >
        View more
      </UdefButton>
    </div>
  </section>
</template>

<script>

import UdefArticleCard from '@/components/UdefArticleCard'

export default {
  name: 'IndexHappening',
  components: { UdefArticleCard },
  props: {
    articles: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.news {
  color: $black-light;
  padding: 80px 50px;

  @media screen and (max-width: $screen-md) {
   padding: 40px 0;
  }

  .news-title {
    font-size: $font-section-title;
    font-weight: $font-weight-bold;
    text-align: center;
    margin-bottom: 43px;

    @media screen and (max-width: $screen-md) {
      font-size: $font-section-title-mobile;
      margin-bottom: 20px;
    }
  }
}

.articles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -20px;

  @media screen and (max-width: $screen-md) {
   justify-content: center;
  }

  .article {
    margin: 20px;
    @media screen and (max-width: $screen-sm) {
      margin: 0;
      margin-bottom: 20px;
    }
  }
}

.action {
  margin-top: 20px;
  text-align: center;
}
</style>
