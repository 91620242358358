<template>
  <div v-if="winners.length" class="background">
    <section class="winners udef-content">
      <h2 class="winners-title">
        Latest winners
      </h2>

      <div class="winners-grid">
        <UdefUserCardVertical
          v-for="(winner, index) in winners"
          :key="index"
          class="winner-item"
          :user="winner"
        />
      </div>
    </section>
  </div>
</template>

<script>
import UdefUserCardVertical from '@/components/UdefUserCardVertical'

export default {
  name: 'IndexWinners',
  components: {
    UdefUserCardVertical
  },
  props: {
    winners: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.background {
  background-color: $dark;
  background-image: url(/img/zoom-logo-multicolor.svg);
}

.winners {
  padding: 70px 50px;

  @media screen and (max-width: $screen-md) {
   padding: 60px 20px;
  }
}

.winners-title {
  font-size: $font-section-title;
  font-weight: $font-weight-bold;
  color: $white;
  text-align: center;
  margin-bottom: 40px;

  @media screen and (max-width: $screen-md) {
   font-size: $font-section-title-mobile;
   margin-bottom: 20px;
  }
}

.winners-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -20px;

  @media screen and (max-width: $screen-md) {
   justify-content: center;
  }

  .winner-item {
    margin: 0 20px 40px;

    @media screen and (max-width: $screen-sm) {
      margin: 0;
      margin-bottom: 20px;
    }
  }
}
</style>
