<template>
  <section>
    <div v-if="blur" class="hero">
      <div class="background">
        <div
          :style="`background: center/ cover no-repeat url(${img})`"
          class="background-image"
        />
        <div class="gradient" />
      </div>
      <div class="content udef-content">
        <slot />
      </div>
    </div>

    <div
      v-else
      class="hero"
    >
      <div
        :style="`background: center/ cover no-repeat url(${img})`"
        class="background-image"
      />
      <div v-if="darkFilter" class="dark-filter" />
      <div class="content udef-content">
        <slot />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UdefHero',
  props: {
    img: {
      type: String,
      required: true
    },
    blur: {
      type: Boolean,
      default: false
    },
    darkFilter: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/global.scss";

.hero {
  position: relative;

  .dark-filter {
  top: 0;
  width: 100%;
  min-height: 100%;
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

  .background-image {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.background {
  overflow: hidden;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  .background-image {
    position: relative;
    height: 100%;
    filter: blur(60px);
  }

  .gradient {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.41) 0%,
      rgba(0, 0, 0, 0.25) 100%
    );
  }
}

.content {
  width: 100%;
  position: relative;
  z-index: 1;
}
</style>
