<template>
  <div class="loader">
    <v-progress-circular
      :size="80"
      color="primary"
      indeterminate
    />
  </div>
</template>

<script>
export default {
  name: 'Loader'
}
</script>

<style lang="scss" scoped>

.loader {
  display: flex;
  justify-content: center;
  margin: 220px 0;
}
</style>
