<template>
  <router-link :to="{ name: 'Article', params: { id:article.id } }" tag="article" class="article">
    <v-img height="305" :src="articleImage" alt="article" />
    <div class="content">
      <h2 class="article-title">
        {{ getStringTruncated(article.title, 45) }}
      </h2>
      <div class="date">
        {{ getDate(article.published) }}
      </div>
      <div class="text">
        {{ getStringTruncated(articleText, 65) }}
      </div>
    </div>
  </router-link>
</template>

<script>
import Truncate from '@/mixins/truncate'
import Dates from '@/mixins/date'
import { DEFAULT_IMAGES } from '@/common/constants'
import Images from '@/mixins/image'

export default {
  name: 'UdefArticleCard',
  mixins: [Truncate, Dates, Images],
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      articleImage: '',
      articleText: ''
    }
  },
  mounted() {
    this.setArticleContent()
  },
  methods: {
    setArticleContent() {
      const htmlText = document.createElement('span')
      htmlText.innerHTML = this.article.content

      this.articleText = htmlText.textContent

      const firstImage = htmlText.querySelector('img')
      this.articleImage = firstImage ? firstImage.src : DEFAULT_IMAGES.article
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.article {
  cursor: pointer;
  box-shadow: $article-shadow;
  max-width: 305px;
  width: 100%;

  @media screen and (max-width: $screen-md) {
    max-width: 335px;
  }

  .content {
    padding: 20px;
  }

  .article-title {
    font-size: $font-big;
    font-weight: $font-weight-bold;
  }

  .date {
    font-size: $font-small;
    color: $grey;
    margin-bottom: 8px
  }
}
</style>
